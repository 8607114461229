import type { UserResponseDto } from '@/shared/contracts/dto/UserResponseDto';
import { Route } from '@/shared/contracts/route';
import { isJunket } from '@/shared/utils/user';

export const canNavigate = (route: Route, user: UserResponseDto) => {
  if (!isJunket(user)) {
    return true;
  }
  return ![Route.fairspin, Route.information].includes(route);
};
