<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import avatarUrl from '@/shared/assets/img/style/user-cap.svg';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';

const emit = defineEmits<{
  (e: 'logout'): void;
}>();
const { t } = useTranslation('common');
const { data } = useUser();
const buttonRef = ref(null);
const menuRef = ref(null);
const open = ref(false);
onClickOutside(menuRef, () => (open.value = false), {
  ignore: [buttonRef],
});
</script>

<template>
  <div
    class="top-header__user dropdown"
    :class="{ open: open }"
  >
    <a
      ref="buttonRef"
      class="dropdown-toggle"
      @click="open = !open"
    >
      <span>
        <img
          class="user-image"
          :src="avatarUrl"
          alt="avatar"
        />
        <i class="right-arrow" />
      </span>
    </a>
    <ul
      ref="menuRef"
      v-if="open"
      class="dropdown-menu"
    >
      <li>
        <div class="user-description">
          <p class="pid-text">({{ t('userMenu.pid') }} {{ data.pid }})</p>
          <p class="email-text">{{ data.email }}</p>
        </div>
      </li>
      <li>
        <RouterLink
          class="ajax-click-exclude-hook"
          :to="{ name: Route.profile }"
        >
          <i class="user-settings-sign" />{{ t('userMenu.settings') }}
        </RouterLink>
      </li>
      <li class="divider" />
      <li>
        <a
          class="ajax-click-exclude-hook"
          @click="() => emit('logout')"
        >
          <i class="user-exit-sign" />{{ t('userMenu.exit') }}
        </a>
      </li>
    </ul>
  </div>
</template>
