import type { HTMLAttributes } from 'vue';
import type { ElementProps } from '@/shared/compositions/floating/types';

function mergeProps(
  props: Partial<HTMLAttributes> | undefined,
  propsList: ElementProps[],
  elementKey: 'reference' | 'floating' | 'item',
) {
  const map = new Map<string, Array<(...args: unknown[]) => void>>();

  return {
    ...(elementKey === 'floating' && { tabIndex: -1 }),
    ...props,
    ...propsList
      .map((value) => (value ? value[elementKey] : null))
      .concat(props)
      .reduce((acc: Record<string, unknown>, props) => {
        if (!props) {
          return acc;
        }

        Object.entries(props).forEach(([key, value]) => {
          if (key.indexOf('on') === 0) {
            if (!map.has(key)) {
              map.set(key, []);
            }

            if (typeof value === 'function') {
              map.get(key)?.push(value);

              acc[key] = (...args: unknown[]) => {
                return map
                  .get(key)
                  ?.map((fn) => fn(...args))
                  .find((val) => val !== undefined);
              };
            }
          } else {
            acc[key] = value;
          }
        });

        return acc;
      }, {}),
  };
}
export const useInteractions = (propsList: ElementProps[]) => {
  const getReferenceProps = (props?: Partial<HTMLAttributes>) =>
    mergeProps(props, propsList, 'reference');
  const getFloatingProps = (props?: Partial<HTMLAttributes>) =>
    mergeProps(props, propsList, 'floating');
  const getItemProps = (props?: Partial<HTMLAttributes>) =>
    mergeProps(props, propsList, 'item');
  return {
    getReferenceProps,
    getFloatingProps,
    getItemProps,
  };
};
