<script setup lang="ts">
import {
  autoUpdate,
  flip,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { useVModel, onClickOutside } from '@vueuse/core';
import { reactive, ref, toRef } from 'vue';
import { useClick } from '@/shared/compositions/floating/useClick';
import { useInteractions } from '@/shared/compositions/floating/useInteractions';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    disabled?: boolean;
    placement?: Placement;
  }>(),
  {
    disabled: false,
    placement: 'bottom',
  },
);
const emit = defineEmits<{
  'update:modelValue': [isOpen: boolean];
}>();

const reference = ref(null);
const floating = ref(null);
const isOpen = useVModel(props, 'modelValue', emit);

const floatingData = useFloating(reference, floating, {
  open: isOpen,
  whileElementsMounted: autoUpdate,
  placement: toRef(() => props.placement),
  middleware: [flip(), shift()],
});
const { x, y, strategy } = floatingData;
const floatingReactive = reactive(floatingData);
const click = useClick(
  { open: isOpen },
  {
    disabled: toRef(() => props.disabled),
  },
);
onClickOutside(
  floating,
  () => {
    isOpen.value = false;
  },
  {
    ignore: [reference],
  },
);
const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
  click,
]);
</script>

<template>
  <div :class="$style.dropdown">
    <slot
      name="reference"
      :setRef="(_ref: any) => (reference = _ref)"
      :getReferenceProps="getReferenceProps"
    />
    <slot
      name="popup"
      :isOpen="isOpen"
      :floating="floatingReactive"
      :getFloatingProps="getFloatingProps"
    >
      <div
        :class="$style.popup"
        ref="floating"
        v-show="isOpen"
        :style="{
          position: strategy,
          top: `${y ?? 0}px`,
          left: `${x ?? 0}px`,
        }"
        v-bind="getFloatingProps()"
      >
        <slot
          name="widget"
          :getItemProps="getItemProps"
        />
      </div>
    </slot>
  </div>
</template>

<style module lang="scss">
.dropdown {
  position: relative;
}
.popup {
  min-width: 100%;
  z-index: 200;
}
</style>
