import { useQuery } from '@tanstack/vue-query';
import { provide, type Ref } from 'vue';
import { getUser } from '@/shared/api/user';
import { useHttp } from '@/shared/compositions/useHttp';
import { userInjectKey } from '@/shared/compositions/user/const';

export const useProvideUser = (params: { enabled: Ref<boolean> }) => {
  const http = useHttp();
  const result = useQuery({
    queryKey: ['user'],
    queryFn: () => {
      return http
        .request(getUser(), {
          handleStatus: false,
          secure: 'private',
        })
        .then((response) => response.data);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: params.enabled,
  });

  provide(userInjectKey, result);

  return result;
};
