<script setup lang="ts"></script>

<template>
  <div :class="$style.list">
    <slot />
  </div>
</template>

<style module lang="scss">
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  outline: 0;
  max-height: 200px;
  overflow: auto;
  background: #fff;
}
</style>
