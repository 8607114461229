<script setup lang="ts">
import UserDesktop from '@/widgets/user-header-panel/ui/UserDesktop.vue';
import UserMobile from '@/widgets/user-header-panel/ui/UserMobile.vue';
import WalletDesktop from '@/widgets/user-header-panel/ui/WalletDesktop.vue';
import { useEndpoints } from '@/shared/compositions/endpoints/useEndpoints';
import { useSession } from '@/shared/compositions/useSession';
import config from '@/shared/configs/config';
import getEnv from '@/shared/getEnv';

const { cpaUrl } = getEnv();
const session = useSession();

const { entity } = useEndpoints();
const logout = () => {
  session.removeAccessData();
  window.location.href = entity.value?.logout || cpaUrl + config.static.exit;
};
</script>

<template>
  <div class="top-header__user-wrapp">
    <div class="top-header__user-desktop">
      <WalletDesktop />
      <UserDesktop @logout="logout" />
    </div>
    <div class="top-header__user-mobile">
      <UserMobile @logout="logout" />
    </div>
  </div>
</template>
