<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { Route } from '@/shared/contracts/route';

const props = defineProps<{
  pageName?: string;
}>();
const { t } = useTranslation('common');
const route = useRoute();
const pageName = computed(() => props.pageName || (route.name as Route));
</script>

<template>
  <div :class="$style.heading">
    <div :class="$style.headingInner">
      <div :class="$style.headingRow">
        <h2 :class="$style.header">{{ t(`page.${pageName}.header`) }}</h2>
        <slot name="afterHeader" />
      </div>
      <slot name="breadcrumbs" />
    </div>
  </div>
  <section class="wrapper-content">
    <slot />
  </section>
</template>

<style module lang="scss">
.heading {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #fff;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #e7eaec;
}
.headingInner {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1px;
}
.headingRow {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header {
  margin-right: auto;
}

.wrapper-content {
  padding: 20px 10px 40px;
}
</style>
