import type { PartialDeep } from 'type-fest';
import type { CurrencyRatiosDto } from '@/shared/contracts/dto/CurrencyRatiosDto';
import type { PaginatedResponse } from '@/shared/contracts/dto/PaginatedResponse';
import type { RegionDto } from '@/shared/contracts/dto/RegionDto';
import type { CommonListRequestDto } from '@/shared/contracts/dto/RequestDto';
import type { UserResponseDto } from '@/shared/contracts/dto/UserResponseDto';
import { createHttpRequest } from '@/shared/utils/http';

export const getUser = () =>
  createHttpRequest<UserResponseDto, { fields?: string }>({
    method: 'post',
    url: '/api/user/user.get',
    data: {
      fields:
        'user.manager,user.roles,user.publisher,user.timezone,user.locale,user.responsible_manager,publisher.name,publisher.surname,publisher.account,publisher.payment_details,publisher.secondary_payment_details,publisher.payment_details_percent,publisher.subscribed,publisher.contact,publisher.contacts',
    },
  });
export const updateUser = (data: PartialDeep<UserResponseDto>) =>
  createHttpRequest<number>({
    method: 'post',
    url: '/api/user/user.edit',
    data,
  });

export const changePassword = (data: {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
  client_id: string;
}) =>
  createHttpRequest<number>({
    method: 'post',
    url: '/api/user/user.changePassword',
    data,
  });

export const getIntegration = (system: number) =>
  createHttpRequest<void>({
    method: 'get',
    url: `/api/user/user.integrate/${system}`,
  });

export const getCurrencies = (data: { _locale: string }) =>
  createHttpRequest<
    Array<{
      name: string;
      subunit: number;
      supported: boolean;
      title: string;
    }>
  >({
    url: '/api/user/currencyRatios.getCurrencies',
    method: 'POST',
    data,
  });

export const getCurrentCurrencyRatios = (data: { _locale: string }) =>
  createHttpRequest<CurrencyRatiosDto>({
    url: '/api/user/currencyRatios.getCurrent',
    method: 'POST',
    data: {
      ...data,
      fields: 'currency_ratio.rates',
    },
  });

export const getCurrencyRatios = (data: {
  _locale: string;
  page?: number;
  count?: number;
  created_at_from?: string;
  created_at_to?: string;
}) =>
  createHttpRequest<PaginatedResponse<CurrencyRatiosDto>>({
    url: '/api/user/currencyRatios.search',
    method: 'POST',
    data: {
      ...data,
      fields: 'currency_ratio.rates',
    },
  });

export const searchRegions = (
  data: CommonListRequestDto & { types?: string },
) =>
  createHttpRequest<PaginatedResponse<RegionDto>>({
    url: '/api/user/regions.search',
    method: 'POST',
    data: {
      fields: 'region.type,region.parent',
      ...data,
    },
  });

export const getServerStorage = (data: { client_id: string }) =>
  createHttpRequest<string>({
    url: '/api/user/clientStorage.get',
    method: 'POST',
    data,
  });

export const setServerStorage = (data: { client_id: string; data: string }) =>
  createHttpRequest({
    url: '/api/user/clientStorage.set',
    method: 'POST',
    data,
  });

export const getFairspinWidget = () =>
  createHttpRequest<{ url: string }>({
    url: '/api/user/user.getWidget',
    method: 'POST',
  });
