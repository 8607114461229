<script setup lang="ts">
import { computed } from 'vue';
import KLoading from '@/shared/kit/KLoading.vue';

const props = withDefaults(
  defineProps<{
    progress?: number;
    minProgress?: number;
    loading?: boolean;
    disable?: boolean;
    type?: 'button' | 'reset' | 'submit';
    color?: 'default' | 'primary' | 'white' | 'info' | 'success';
    size?: 'xs' | 'sm' | 'lg';
    variant?: 'outline';
  }>(),
  {
    type: 'button',
    minProgress: 0.05,
  },
);
const progressValue = computed(() => {
  if (!props.progress) {
    return 0;
  }

  return (
    (props.progress > props.minProgress ? props.progress : props.minProgress) *
    100
  );
});
</script>

<template>
  <button
    class="btn btn-loading"
    :class="{
      [`btn-${color}`]: color,
      [`btn-${size}`]: size,
      [`btn-${variant}`]: variant,
      'btn-loading_active': loading,
    }"
    :type="type"
    :disabled="disable || loading"
  >
    <span class="btn-loading__label">
      <slot />
    </span>
    <slot name="after" />
    <span
      v-if="loading"
      class="btn-loading__spiner"
    >
      <KLoading
        :color="color === 'white' ? undefined : 'white'"
        inherit
      />
    </span>
    <span
      class="btn-loading__progress"
      :style="{ width: `${progressValue}%` }"
    />
  </button>
</template>
