import { getIntegration } from '@/shared/api/user';
import { useHttp } from '@/shared/compositions/useHttp';
import config from '@/shared/configs/config';
import { useTruePlayStore } from '@/shared/stores/truePlayStore';

export const useTruePlayIntegration = () => {
  const store = useTruePlayStore();
  const http = useHttp();

  const init = () => {
    store.setLoading(true);
    return http
      .request(getIntegration(config.integrationSystems.Trueplay), {
        handleStatus: false,
        secure: 'private',
      })
      .then((response) => {
        const integrated =
          response.status === config.integrationResponseStatuses.ok ||
          response.status === config.integrationResponseStatuses.created;

        store.setIntegrated(integrated);
      })
      .finally(() => store.setLoading(false));
  };

  return {
    init,
    store,
  };
};
