import { computed, type MaybeRefOrGetter, toValue } from 'vue';
import type { SelectItemKey } from '@/shared/utils/helpers';
import { getPropertyFromItem } from '@/shared/utils/helpers';

export interface InternalItem<T = any> {
  title: string;
  value: any;
  raw: T;
}
export interface ItemParams {
  items: MaybeRefOrGetter<any[] | ReadonlyArray<any>>;
  itemTitle: MaybeRefOrGetter<SelectItemKey>;
  itemValue: MaybeRefOrGetter<SelectItemKey>;
  returnObject: MaybeRefOrGetter<boolean>;
}
export function transformItem(params: Omit<ItemParams, 'items'>, item: any) {
  const title = getPropertyFromItem(item, toValue(params.itemTitle), item);
  const value = toValue(params.returnObject)
    ? item
    : getPropertyFromItem(item, toValue(params.itemValue), title);

  return {
    title: String(title ?? ''),
    value: value,
    raw: item,
  };
}
export function transformItems(
  params: Omit<ItemParams, 'items'>,
  items: ItemParams['items'],
) {
  return toValue(items).map((item) => transformItem(params, item));
}

export function useItems(params: ItemParams) {
  const items = computed(() => transformItems(params, params.items));

  function transformIn(value: any[]): InternalItem[] {
    return value.map((item) => transformItem(params, item));
  }

  function transformOut(value: InternalItem[]) {
    return value.map(({ value }) => value);
  }

  return {
    items,
    transformIn,
    transformOut,
    transformItem: (item: any) => transformItem(params, item),
  };
}
