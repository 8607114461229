<script setup lang="ts">
import { useTranslation } from 'i18next-vue';
import { computed, ref, watch } from 'vue';
import KLoading from '@/shared/kit/KLoading.vue';
import { useTruePlayIntegration } from '@/feature/trueplay/useTruePlayIntegration';
import config from '@/shared/configs/config';
import { useAppPreload } from '@/widgets/auth-preloading/lib/useAppPreload';

const { i18next } = useTranslation();
const { preloading, userQuery } = useAppPreload();
const { init: truePlayInit, store: truePlayStore } = useTruePlayIntegration();
const inited = ref(false);

watch(
  () => userQuery.data.value?.locale,
  () => {
    if (userQuery.data.value?.locale) {
      i18next.changeLanguage(userQuery.data.value?.locale);
    }
  },
  {
    immediate: true,
  },
);

const isReady = computed(() => {
  if (preloading.value) {
    return false;
  }
  if (inited.value) {
    return true;
  }
  const locale = userQuery.data.value?.locale;
  if (!locale) {
    return true;
  }
  return i18next.language === locale;
});
watch(isReady, () => {
  if (isReady.value) {
    inited.value = true;
  }
});

const tryIntegrateTruePlay = () => {
  truePlayInit().then(() => {
    if (!truePlayStore.isIntegrated) {
      setTimeout(tryIntegrateTruePlay, config.integrationRequestInterval);
    }
  });
};

watch(
  preloading,
  () => {
    if (
      !preloading.value &&
      !truePlayStore.isIntegrated &&
      !truePlayStore.isLoading
    ) {
      tryIntegrateTruePlay();
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div
    v-if="!isReady"
    class="global-loading"
  >
    <KLoading center />
  </div>
  <template v-else>
    <slot />
  </template>
</template>

<style lang="scss"></style>
