import getEnv from '@/shared/getEnv';

const { cpaUrl, clientId } = getEnv();
export const redirectToLogin = () => {
  const location = window.location;
  const baseUrl = `${location.origin}${location.pathname}${location.search}`;
  window.location.assign(
    `${cpaUrl}/user/get_access_token?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      baseUrl,
    )}`,
  );
};
