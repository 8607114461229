import { useMutation, useQuery } from '@tanstack/vue-query';
import { computed, ref } from 'vue';
import { getNotification, saveViewNotification } from '@/shared/api/publisher';
import { useHttp } from '@/shared/compositions/useHttp';
import { useUser } from '@/shared/compositions/user/useUser';
import config from '@/shared/configs/config';

export const useNotification = () => {
  const http = useHttp();
  const user = useUser();

  const enabled = ref(true);
  const waitingNewData = ref(false);

  const polling = useQuery({
    enabled: enabled,
    gcTime: 0,
    refetchInterval: config.notificationUpdateTime * 1000,
    queryKey: computed(() => ['publisher/notification/get', user.data.locale]),
    queryFn: () =>
      http
        .request(
          getNotification({
            _locale: user.data.locale,
          }),
          {
            secure: 'private',
            handleStatus: false,
          },
        )
        .then((response) => response.data ?? null)
        .finally(() => {
          waitingNewData.value = false;
        }),
  });

  const { mutate: close } = useMutation({
    mutationFn: (params: { id: string }) =>
      http
        .request(
          saveViewNotification({
            notification_id: params.id,
          }),
          {
            secure: 'private',
            handleStatus: false,
          },
        )
        .then((response) => response.data),
    onMutate: () => {
      waitingNewData.value = true;
      enabled.value = false;
    },
    onSettled: () => {
      enabled.value = true;
      // polling.refetch() //probably dont needed
    },
  });

  return {
    waitingNewData,
    polling,
    close,
  };
};
