<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import { getLocaleImageUrl, getLocaleJsonCodeList } from '@/locales-loader';
import { useUser } from '@/shared/compositions/user/useUser';

const { data, actions } = useUser();
const list = getLocaleJsonCodeList();
const buttonRef = ref(null);
const menuRef = ref(null);
const open = ref(false);
onClickOutside(menuRef, () => (open.value = false), {
  ignore: [buttonRef],
});
const changeLocale = (locale: string) => {
  actions.changeLocale(locale);
  open.value = false;
};
</script>

<template>
  <div class="locale">
    <div
      class="dropdown"
      :class="{ open }"
    >
      <a
        ref="buttonRef"
        class="dropdown-toggle menu"
        @click="open = !open"
      >
        <span>
          <i class="flag-icon">
            <img
              :src="getLocaleImageUrl(data.locale!, 'flag.svg')"
              :alt="data.locale"
            />
          </i>
        </span>
      </a>
      <ul
        ref="menuRef"
        class="dropdown-menu"
      >
        <li
          v-for="locale in list"
          :key="locale"
        >
          <a
            :class="{ disabled: data.locale === locale }"
            @click="() => changeLocale(locale)"
          >
            <i class="flag-icon">
              <img
                :src="getLocaleImageUrl(locale, 'flag.svg')"
                :alt="locale"
              /> </i
            >{{ locale.toLocaleUpperCase() }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
