import type { AxiosInstance, AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';
import type {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '@/shared/contracts/http';
import { HttpError, HttpErrorReason } from '@/shared/contracts/http';

const isAxiosError = (reason: any): reason is AxiosError => {
  return 'isAxiosError' in reason && reason.isAxiosError;
};

export default class AxiosAdapter implements HttpClient {
  private axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      withCredentials: true,
    });
    this.axiosInstance.defaults.withCredentials = true;
  }

  private axiosToAppResponse(
    response: AxiosResponse,
    request: HttpRequest,
  ): HttpResponse {
    const { headers, status, statusText, data } = response;
    return {
      headers: headers as HttpResponse['headers'],
      request: request,
      status,
      data,
      statusText,
    };
  }

  private mapErrorCode = (code?: string | null): HttpErrorReason | null => {
    switch (code) {
      case AxiosError.ECONNABORTED:
        return HttpErrorReason.CONN_ABORTED;
      case AxiosError.ETIMEDOUT:
        return HttpErrorReason.TIME_OUT;
      case AxiosError.ERR_NETWORK:
        return HttpErrorReason.ERR_NETWORK;
      case AxiosError.ERR_BAD_RESPONSE:
        return HttpErrorReason.BAD_STATUS;
      case AxiosError.ERR_CANCELED:
        return HttpErrorReason.CANCELED;
      default:
        return null;
    }
  };

  request<T = any, D = any>(
    request: HttpRequest<D>,
  ): Promise<HttpResponse<T, D>> {
    const { paramsSerializer, ...requestOther } = request;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request({
          ...requestOther,
          paramsSerializer: paramsSerializer && {
            serialize: paramsSerializer,
          },
        })
        .then((axiosResponse) =>
          resolve(this.axiosToAppResponse(axiosResponse, request)),
        )
        .catch((reason) => {
          if (isAxiosError(reason)) {
            reject(
              new HttpError(
                reason.message,
                this.mapErrorCode(reason.code),
                request,
                reason.response &&
                  this.axiosToAppResponse(reason.response, request),
              ),
            );
          } else {
            reject(reason);
          }
        });
    });
  }
}
