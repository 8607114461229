<script setup lang="ts">
import { ref } from 'vue';
import CurrencyString from '@/entities/currency/CurrencyString.vue';
import ChangeTimezone from '@/feature/change-timezone/ChangeTimezone.vue';
import WalletMobile from '@/widgets/user-header-panel/ui/WalletMobile.vue';
import avatarUrl from '@/shared/assets/img/style/user-cap.svg';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';

const emit = defineEmits<{
  (e: 'logout'): void;
}>();
const { t } = useTranslation('common');
const { data } = useUser();
const open = ref(false);
const close = () => {
  open.value = false;
};
</script>

<template>
  <div
    class="top-header__user dropdown"
    :class="{ open: open }"
  >
    <a
      ref="buttonRef"
      class="dropdown-toggle"
      @click="open = !open"
    >
      <img
        class="user-image"
        :src="avatarUrl"
        alt="avatar"
      />
    </a>
    <div class="dropdown-user-info">
      <div class="dropdown-user-top-block">
        <div class="user-description">
          <p class="pid-text">({{ t('userMenu.pid') }} {{ data.pid }})</p>
          <p class="email-text">{{ data.email }}</p>
        </div>
        <div class="user-balance">
          <WalletMobile />
          <div
            class="user-balance-hold"
            v-if="
              data.holdBalance &&
              data.holdBalance.amount &&
              data.holdBalance.currency
            "
          >
            <i class="hold-sign" />
            <div class="hold-block">
              <div class="text">{{ t('user.hold') }}</div>
              <div class="num">
                <CurrencyString
                  type="short"
                  :amount="data.holdBalance.amount"
                  :iso="data.holdBalance.currency"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="user-timezone">
          <ChangeTimezone />
        </div>
      </div>
      <div class="dropdown-user-bottom-block">
        <div class="user-bottom-block">
          <RouterLink
            class="ajax-click-exclude-hook"
            :to="{ name: Route.profile }"
            @click="close"
          >
            <i class="user-settings-sign" />
            <span>{{ t('userMenu.settings') }}</span>
          </RouterLink>
        </div>
        <div class="divider" />
        <div class="user-bottom-block">
          <a
            class="ajax-click-exclude-hook"
            @click="() => emit('logout')"
          >
            <i class="user-exit-sign" />
            <span>{{ t('userMenu.exit') }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
