<script setup lang="ts">
import { computed } from 'vue';
import ServerError from '@/entities/page/ServerError.vue';
import KLoading from '@/shared/kit/KLoading.vue';
import { useProvideEndpoints } from '@/shared/compositions/endpoints/useProvideEndpoints';

const { isFetched, isError } = useProvideEndpoints();
const preloading = computed(() => !isFetched.value);
</script>

<template>
  <ServerError v-if="isError" />
  <div
    v-else-if="preloading"
    class="global-loading"
  >
    <KLoading center />
  </div>
  <template v-else>
    <slot />
  </template>
</template>

<style lang="scss"></style>
