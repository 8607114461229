import type { MaybeRef } from '@vueuse/shared';
import { castArray } from 'lodash';
import { computed, toValue, unref } from 'vue';
import {
  type InternalItem,
  type ItemParams,
  useItems,
} from '@/shared/compositions/useItems';

export interface SelectParams extends ItemParams {
  value: MaybeRef<any | any[]>;
  multiple?: MaybeRef<boolean>;
  onUpdate: (v: any) => void;
  valueComparator: (a: any, b: any) => boolean;
}
export function useSelect(params: SelectParams) {
  const { items, transformIn, transformOut, transformItem } = useItems(params);

  const { onUpdate, valueComparator } = params;

  const model = computed({
    get() {
      const val = unref(params.value);
      return transformIn(val == null ? [] : castArray(val));
    },
    set(internalItem) {
      const transformed = transformOut(internalItem);
      onUpdate(unref(params.multiple) ? transformed : transformed[0] ?? null);
    },
  });

  const selectedItems = computed(() => {
    return model.value.map((v) => {
      return (
        items.value.find((item) => {
          return valueComparator(item.value, v.value);
        }) || v
      );
    });
  });
  const selected = computed(() =>
    selectedItems.value.map((selection) => selection.value),
  );
  function select(item: InternalItem) {
    if (toValue(params.multiple)) {
      const index = selected.value.findIndex((selection) =>
        valueComparator(selection, item.value),
      );

      if (index === -1) {
        model.value = [...model.value, item];
      } else {
        const value = [...model.value];
        value.splice(index, 1);
        model.value = value;
      }
    } else {
      model.value = [item];
    }
  }
  function clear() {
    model.value = [];
  }
  function isSelected(value: any) {
    return (
      selected.value.find((selection) => valueComparator(selection, value)) !==
      undefined
    );
  }

  return {
    items,
    selectedItems,
    select,
    clear,
    isSelected,
    transformItem,
  };
}
