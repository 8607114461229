export class CountryTimezones {
  constructor(
    private data: Array<{
      country_code: string;
      timezones: Array<string>;
    }>,
  ) {}

  getAllCountries() {
    return this.data;
  }
  getCountry(code: string) {
    return (
      this.data.find(
        ({ country_code }) => country_code === code.toUpperCase(),
      ) || null
    );
  }

  getTimezonesForCountry(code: string) {
    return this.getCountry(code)?.timezones || [];
  }

  getCountryForTimezone(tzName: string) {
    return (
      this.data.find(({ timezones }) =>
        timezones.some((tz) => tz === tzName),
      ) || null
    );
  }
}
