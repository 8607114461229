<script setup lang="ts">
import KButton from '@/shared/kit/KButton.vue';
import { useTranslation } from '@/shared/compositions/useTranslation';

const { t } = useTranslation('common');
withDefaults(
  defineProps<{
    withFooter?: boolean;
    is?: string;
    size?: 'large' | 'default' | 'small' | 'responsive';
    padding?: 'small' | 'default';
  }>(),
  {
    size: 'default',
    withFooter: true,
    is: 'div',
  },
);
const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>

<template>
  <component
    :is="is"
    :class="[
      $style.modal,
      {
        [$style[`size-${size}`]]: size !== 'default',
      },
    ]"
  >
    <slot>
      <div :class="$style.header">
        <slot name="header">
          <h4 :class="$style.title"><slot name="title" /></h4>
          <button
            type="button"
            :class="$style.closeButton"
            @click="emit('close')"
          >
            <span aria-hidden="true">×</span>
          </button>
        </slot>
      </div>
      <div
        :class="[
          $style.body,
          {
            [$style[`padding-${padding}`]]: padding !== 'default',
          },
        ]"
      >
        <slot name="body" />
      </div>
      <div
        v-if="withFooter"
        :class="$style.footer"
      >
        <slot name="footer">
          <KButton
            :class="$style.actionClose"
            color="default"
            @click="emit('close')"
            >{{ t('action.cancel') }}</KButton
          >
          <slot name="actions" />
        </slot>
      </div>
    </slot>
  </component>
</template>

<style module lang="scss">
.modal {
  background-clip: padding-box;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 600px;
  }
}
.size-small {
  @media (min-width: 768px) {
    width: 300px;
  }
}
.size-large {
  @media (min-width: 992px) {
    width: 900px;
  }
}
.size-responsive {
  width: auto;
  max-width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
}
.header {
  position: relative;
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.title {
  margin: 0;
  line-height: 1.42857143;
}
.closeButton {
  margin-left: auto;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;

  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}
.body {
  padding: 20px 30px 30px 30px;
}
.padding-small {
  padding: 20px 10px;
}
.footer {
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.actionClose {
  margin-right: auto;
}
</style>
