import { fromPairs, isNil } from 'lodash';
import qs from 'qs';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useHttp } from '@/shared/compositions/useHttp';
import { useSession } from '@/shared/compositions/useSession';
import { useProvideImpersonating } from '@/shared/compositions/user/useProvideImpersonating';
import { useProvideUser } from '@/shared/compositions/user/useProvideUser';
import { redirectToLogin } from '@/shared/lib';
import { isNumeric } from '@/shared/utils/lang';

// import config from "@/shared/configs/config";

export const useAppPreload = () => {
  const router = useRouter();
  const route = useRoute();

  const checkedAuth = ref(false);

  const session = useSession();
  const http = useHttp();

  const userQuery = useProvideUser({ enabled: checkedAuth });
  const impersonating = useProvideImpersonating();
  const preloading = computed(() => {
    return !userQuery.isFetched.value;
  });
  // const getRefreshTokenTime = () => {
  //   const { expires_at, created_at } = session.getAccessData() || {};
  //
  //   if (!expires_at || !created_at) {
  //     return 0;
  //   }
  //   const time =
  //     (parseInt(expires_at) - parseInt(created_at)) *
  //     config.percentTimeUpdateToken *
  //     1000;
  //   return Math.max(time, 0);
  // };
  // const refreshLoop = () => {
  //   const { refresh_token } = session.getAccessData() || {};
  //   if (!refresh_token) {
  //     redirectToLogin();
  //   }
  //   http.refreshAccess();
  //   setTimeout(refreshLoop, getRefreshTokenTime());
  // };
  onMounted(() => {
    const hashValue = qs.parse(
      window.location.hash.replace(/^\?|#/, ''),
    ) as Record<string, string>;
    const {
      access_token,
      impersonating_user,
      refresh_token,
      created_at,
      expires_at,
    } = hashValue;

    const accessDate = session.getAccessData();
    if (!access_token && !accessDate) {
      console.warn('redirect to login bcs miss auth data');
      redirectToLogin();
    } else {
      const impersonatingUser =
        impersonating_user || accessDate?.impersonating_user;
      impersonating.value = impersonatingUser;

      if (access_token) {
        session.setAccessData({
          access_token,
          impersonating_user,
          refresh_token,
          created_at,
          expires_at,
        });
      }
      if (session.isExpiredAccess()) {
        http.refreshAccess().then(() => {
          checkedAuth.value = true;
        });
      } else {
        checkedAuth.value = true;
      }
      // setTimeout(refreshLoop, getRefreshTokenTime());
    }
    router.isReady().then(() => {
      const clearQuery = fromPairs(
        Object.entries(route.query).filter(([key, value]) => {
          return !(isNumeric(key) && isNil(value));
        }),
      );
      router.replace({
        ...route,
        hash: undefined,
        query: clearQuery,
      });
    });
  });

  return {
    preloading,
    userQuery,
  };
};
