<script setup lang="ts">
import { isNil } from 'lodash';
import { RouterLink } from 'vue-router';
import CurrencyString from '@/entities/currency/CurrencyString.vue';
import KTooltip from '@/shared/kit/KTooltip.vue';
import WalletInfo from '@/widgets/user-header-panel/ui/WalletInfo.vue';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';

const { t } = useTranslation('common');
const { data } = useUser();
</script>

<template>
  <KTooltip
    placement="bottom"
    theme="white"
  >
    <template #reference="{ setRef }">
      <WalletInfo
        :ref="setRef"
        class="top-header__balance"
        v-if="data.currency && !isNil(data.summaryBalance)"
        :is="RouterLink"
        :to="{ name: Route.payout }"
        :currency="data.currency"
        :list="[
          {
            key: 'balance',
            title: `${t('user.balance')}: `,
            value: data.summaryBalance,
          },
        ]"
      />
    </template>
    <template #default>
      <template v-if="data.currency">
        <p
          v-for="item in data.balanceList"
          :key="item.type"
        >
          {{ item.type }}:
          <CurrencyString
            :amount="item.amount"
            :iso="data.currency"
          />
        </p>
        <p v-if="data.holdBalance">
          {{ t('user.hold') }}:
          <CurrencyString
            :amount="data.holdBalance.amount"
            :iso="data.holdBalance.currency"
          />
        </p>
      </template>
    </template>
  </KTooltip>
</template>
