<script setup lang="ts">
import type { Alignment, Side } from '@floating-ui/core';
import {
  arrow,
  autoUpdate,
  flip,
  type MaybeElement,
  offset,
  type Placement,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { useElementHover } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

const props = withDefaults(
  defineProps<{
    placement?: Placement;
    reference?: MaybeElement<Element>;
    show?: boolean;
    theme?: 'white' | 'preview';
    maxSize?: 'default' | 'auto';
  }>(),
  {
    show: undefined,
    placement: 'top',
    maxSize: ({ theme }) => (theme === 'preview' ? 'auto' : 'default'),
  },
);
const floatingRef = ref<HTMLElement | null>(null);
const arrowRef = ref<HTMLElement | null>(null);

const arrowLen = computed(() => arrowRef.value?.offsetWidth || 0);
const floatingOffset = computed(() => Math.sqrt(2 * arrowLen.value ** 2) / 2);

const slotReference = ref(null);
const setSlotRef = (el: any) => {
  slotReference.value = el;
};
const isHovered = useElementHover(slotReference, {
  delayLeave: 50,
});
const innerReference = computed(() => props.reference || slotReference.value);
const innerShow = computed(() => {
  return props.show ?? isHovered.value;
});

const {
  middlewareData,
  placement: resPlacement,
  floatingStyles,
} = useFloating(innerReference, floatingRef, {
  open: innerShow,
  whileElementsMounted: autoUpdate,
  placement: toRef(props, 'placement'),
  middleware: computed(() => [
    offset(floatingOffset.value),
    shift(),
    flip(),
    arrow({
      element: arrowRef,
    }),
  ]),
});
const arrowData = computed(() => middlewareData.value.arrow);
const placementInfo = computed(() => {
  const [side, alignment] = resPlacement.value.split('-') as [
    Side,
    Alignment | undefined,
  ];
  const reverseSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[side] as Side;
  return { side, alignment, reverseSide };
});
</script>

<template>
  <slot
    name="reference"
    :setRef="setSlotRef"
  />
  <Transition :name="$style.fade">
    <div
      ref="floatingRef"
      :class="[
        $style.tooltip,
        {
          [$style[`theme-${theme}`]]: theme,
          [$style[`maxSize-${maxSize}`]]: maxSize,
        },
      ]"
      v-if="innerShow"
      :style="floatingStyles"
    >
      <div :class="$style.content">
        <slot />
      </div>
      <div
        ref="arrowRef"
        :class="$style.arrow"
        :style="{
          top: `${arrowData?.y ?? 0}px`,
          left: `${arrowData?.x ?? 0}px`,
          right: '',
          bottom: '',
          [placementInfo.side]: '',
          [placementInfo.reverseSide]: `${-arrowLen / 2}px`,
        }"
      />
    </div>
  </Transition>
</template>

<style module lang="scss">
.fade {
  &:global(-enter-active) {
    transition: opacity 0.3s ease;
  }
  &:global(-leave-active) {
    transition: opacity 0.3s ease;
  }
  &:global(-enter-from) {
    opacity: 0;
  }
  &:global(-leave-to) {
    opacity: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1;
  --tltp-bg: #000;
  --tltp-clr: #fff;

  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857143;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
}
.tooltip.theme-white {
  border: 1px solid #dddddd;
  border-radius: 4px;
  --tltp-bg: #fff;
  --tltp-clr: #000;
}
.theme-white .content {
  padding: 12px 10px;
  font-size: 13px;
}
.theme-preview {
  .content {
    padding: 2px;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
  .arrow {
    display: none;
  }
}
.content {
  width: max-content;
  padding: 3px 8px;
  color: var(--tltp-clr);
  background-color: var(--tltp-bg);
  border-radius: 4px;

  :global(p) {
    margin: 0;
  }
  :global(p + p) {
    margin-top: 5px;
  }
}
.maxSize-default .content {
  max-width: 250px;
}
.maxSize-auto .content {
  max-width: none;
}
.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  z-index: -1;
  pointer-events: none;
  background-color: var(--tltp-bg);
}
</style>
