import { useQuery } from '@tanstack/vue-query';
import { provideContext } from '@/shared/compositions/endpoints/context';
import type { EndpointsDto } from '@/shared/contracts/dto/EndpointsDto';
import type { ApiResponseStructureV2 } from '@/shared/contracts/response';
import getEnv from '@/shared/getEnv';

const { cpaUrl } = getEnv();

export const useProvideEndpoints = () => {
  const result = useQuery({
    queryKey: ['endpoints'],
    queryFn: () =>
      fetch(`${cpaUrl}/api/v2/endpoints`)
        .then((response) => response.json())
        .then((_resp) => {
          const response = _resp as ApiResponseStructureV2<EndpointsDto>;
          if (Array.isArray(response.errors) && response.errors.length) {
            throw {
              error: {
                code: response.code,
                message: response.errors.join('; '),
              },
            };
          }
          return response.data;
        }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  provideContext(result);
  return result;
};
