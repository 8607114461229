import { range } from 'lodash';

const ADDITIONAL_PARAM_COUNT = 9;

const config = {
  percentDecimalFixedValue: 2,
  decimalFixedValue: 2,
  reportDateFormat: 'yyyy-MM-dd',
  reportHourFormat: 'yyyy-MM-dd HH:mm:ss',
  defaultDatepickerDaysPeriod: 6,
  commonMetaParams: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    ...range(1, ADDITIONAL_PARAM_COUNT + 1).map((i) => `param${i}`),
  ],
  social: {
    facebook: 'https://www.facebook.com/BonaFidesAff',
    telegram: 'https://t.me/+qDlJ3g13yug0ZDFi',
    instagram: 'https://www.instagram.com/bonafides_aff/',
    twitter: 'https://twitter.com/BonaFides_Aff',
    linkedin: 'https://www.linkedin.com/company/85642522/admin/feed/posts/',
  },
  explorerSettings: [
    {
      pattern: 'bx[a-fA-F0-9]{33}',
      url: 'https://explorer.trueplay.io/',
      paths: {
        sale: 'transaction/{{cid}}&date={{date}}',
        expense: 'transaction/{{cid}}&date={{date}}',
      },
    },
    {
      pattern:
        '^[0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12}$',
      url: 'https://explorer.trueplay.io/',
      paths: {
        sale: 'transaction/{{cid}}',
        expense: 'transaction/{{cid}}',
      },
    },
  ],
  locales: [
    {
      title: 'ru',
      value: 'ru',
    },
    {
      title: 'en',
      value: 'en',
    },
  ],
  static: {
    emailLinkPath: '/user/profile/',
    exit: '/en/user/logout',
    exitSwitch: '/admin/users/edit/{pid}?_switch_user=_exit',
    managerInfo: {
      email: 'support@bonafides.team',

      contacts: [
        { type: 'skype', value: 'live:.cid.52dabc2f5d2209d0' },
        { type: 'telegram', value: 'BonaFidesSupport' },
      ],
    },
  },
  nameCookie: 'd0412f48687b4419b4cfccd2b2b5750e',
  percentTimeUpdateToken: 0.8,
  defaultRoute: '/',
  itemsCountPerPage: 20,
  defaultDatepickerMonthPayoutPeriod: 5,
  defaultLastDays: 7,
  reportItemsCount: 1000,
  decimalFixedBTCValue: 5,
  decimalShortValue: 2,
  decimalShortBTCValue: 2,
  maxCountPostbacks: 100,
  notificationUpdateTime: 300,
  integrationSystems: {
    Trueplay: 1,
  },
  integrationRequestInterval: 30000,
  integrationResponseStatuses: {
    ok: 200,
    created: 201,
    accepted: 202,
  },
} as const;

export default config;
