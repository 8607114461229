import { isCrypto, formatCurrency as format } from '@coingecko/cryptoformat';
import config from '@/shared/configs/config';

export const formatCurrency = (
  value: number,
  isoCode: string,
  type: 'default' | 'short' | 'raw' = 'default',
) => {
  const _value = isoCode === 'BTC' ? value * 1000 : value;
  const locale = isCrypto(isoCode) ? 'bez' : 'en';
  let formatted;
  switch (type) {
    case 'raw': {
      formatted = format(_value, isoCode, locale, true);
      break;
    }
    case 'short': {
      formatted = format(_value, isoCode, locale, false, {
        decimalPlaces:
          isoCode === 'BTC'
            ? config.decimalShortBTCValue
            : config.decimalShortValue,
      });
      break;
    }
    default: {
      formatted = format(_value, isoCode, locale, false, {
        decimalPlaces:
          isoCode === 'BTC'
            ? config.decimalFixedBTCValue
            : config.decimalFixedValue,
      });
      break;
    }
  }
  return isoCode === 'BTC' ? formatted.replace('BTC', 'mBTC') : formatted;
};
