import { watch } from 'vue';
import { useEndpoints } from '@/shared/compositions/endpoints/useEndpoints';
import { useAppDependencies } from '@/shared/plugins/appContext';

export const useHttp = () => {
  const { httpService: http } = useAppDependencies();
  const { entity } = useEndpoints();
  watch(
    () => entity.value?.api,
    (api) => {
      if (api) {
        http.apiUrl = api;
      }
    },
    {
      immediate: true,
    },
  );

  return http;
};
