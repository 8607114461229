<script setup lang="ts">
import { formatInTimeZone } from 'date-fns-tz';
import { computed } from 'vue';
import { useModal } from 'vue-final-modal';
import TimezoneModal from '@/feature/change-timezone/TimezoneModal.vue';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';

const { t: tTimezone } = useTranslation('timezones');
const { data } = useUser();
const tzName = computed(() => {
  if (!data.timezone) {
    return '';
  }
  if (data.timezone === 'UTC') {
    return data.timezone;
  }
  const utc = formatInTimeZone(new Date(), data.timezone, 'xxx');
  return `(UTC ${utc}) ${tTimezone(data.timezone)}`;
});

const { open: openModal } = useModal({
  component: TimezoneModal,
  attrs: {
    // @ts-ignore
    initValue: computed(() => data.timezone),
  },
});
</script>

<template>
  <div class="timezone">
    <a
      class="timezone__current"
      @click="openModal"
    >
      <i class="timezone__current-icon" />
      <span class="timezone__current-name">{{ tzName }}</span>
    </a>
  </div>
</template>

<style scoped lang="scss"></style>
