<script setup lang="ts">
import { isNil } from 'lodash';
import { computed } from 'vue';
import WalletInfo from '@/widgets/user-header-panel/ui/WalletInfo.vue';
import { useUser } from '@/shared/compositions/user/useUser';

const { data } = useUser();
const list = computed(() =>
  data.balanceList.map((account) => ({
    key: account.type,
    value: account.amount,
    title: account.type,
  })),
);
</script>

<template>
  <WalletInfo
    class="user-balance-payout"
    v-if="data.currency && !isNil(data.summaryBalance)"
    :currency="data.currency"
    :list="list"
  />
</template>
