import type { AccessData } from '@/shared/contracts/entity/AccessData';
import { createHttpRequest } from '@/shared/utils/http';

export const refreshToken = (params: {
  refresh_token: string;
  client_id: string;
}) => {
  return createHttpRequest<AccessData>({
    url: '/user/refresh_access_token',
    method: 'GET',
    params,
  });
};
