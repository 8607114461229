export const countries: Array<{
  country_code: string;
  timezones: Array<string>;
}> = [
  {
    timezones: ['America/Aruba'],
    country_code: 'AW',
  },
  {
    timezones: ['Africa/Luanda'],
    country_code: 'AO',
  },
  {
    timezones: ['America/Anguilla'],
    country_code: 'AI',
  },
  {
    timezones: ['Europe/Mariehamn'],
    country_code: 'AX',
  },
  {
    timezones: ['Europe/Tirane'],
    country_code: 'AL',
  },
  {
    timezones: ['Europe/Andorra'],
    country_code: 'AD',
  },
  {
    timezones: ['Asia/Dubai'],
    country_code: 'AE',
  },
  {
    timezones: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia',
    ],
    country_code: 'AR',
  },
  {
    timezones: ['Asia/Yerevan'],
    country_code: 'AM',
  },
  {
    timezones: ['Pacific/Pago_Pago'],
    country_code: 'AS',
  },
  {
    timezones: [
      'Antarctica/Palmer',
      'Antarctica/Rothera',
      'Antarctica/Troll',
      'Antarctica/Syowa',
      'Antarctica/Mawson',
      'Antarctica/Vostok',
      'Antarctica/Davis',
      'Antarctica/DumontDUrville',
      'Antarctica/Casey',
      'Antarctica/McMurdo',
    ],
    country_code: 'AQ',
  },
  {
    timezones: ['Indian/Kerguelen'],
    country_code: 'TF',
  },
  {
    timezones: ['America/Antigua'],
    country_code: 'AG',
  },
  {
    timezones: [
      'Australia/Perth',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Currie',
      'Australia/Melbourne',
      'Australia/Sydney',
    ],
    country_code: 'AU',
  },
  {
    timezones: ['Europe/Vienna'],
    country_code: 'AT',
  },
  {
    timezones: ['Asia/Baku'],
    country_code: 'AZ',
  },
  {
    timezones: ['Africa/Bujumbura'],
    country_code: 'BI',
  },
  {
    timezones: ['Europe/Brussels'],
    country_code: 'BE',
  },
  {
    timezones: ['Africa/Porto-Novo'],
    country_code: 'BJ',
  },
  {
    timezones: ['Africa/Ouagadougou'],
    country_code: 'BF',
  },
  {
    timezones: ['Asia/Dhaka'],
    country_code: 'BD',
  },
  {
    timezones: ['Europe/Sofia'],
    country_code: 'BG',
  },
  {
    timezones: ['Asia/Bahrain'],
    country_code: 'BH',
  },
  {
    timezones: ['America/Nassau'],
    country_code: 'BS',
  },
  {
    timezones: ['Europe/Sarajevo'],
    country_code: 'BA',
  },
  {
    timezones: ['America/St_Barthelemy'],
    country_code: 'BL',
  },
  {
    timezones: ['Europe/Minsk'],
    country_code: 'BY',
  },
  {
    timezones: ['America/Belize'],
    country_code: 'BZ',
  },
  {
    timezones: ['Atlantic/Bermuda'],
    country_code: 'BM',
  },
  {
    timezones: ['America/La_Paz'],
    country_code: 'BO',
  },
  {
    timezones: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco',
    ],
    country_code: 'BR',
  },
  {
    timezones: ['America/Barbados'],
    country_code: 'BB',
  },
  {
    timezones: ['Asia/Brunei'],
    country_code: 'BN',
  },
  {
    timezones: ['Asia/Thimphu'],
    country_code: 'BT',
  },
  {
    timezones: ['Africa/Gaborone'],
    country_code: 'BW',
  },
  {
    timezones: ['Africa/Bangui'],
    country_code: 'CF',
  },
  {
    timezones: [
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Blanc-Sablon',
      'America/Toronto',
      'America/Nipigon',
      'America/Thunder_Bay',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Atikokan',
      'America/Winnipeg',
      'America/Rainy_River',
      'America/Resolute',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Creston',
      'America/Dawson_Creek',
      'America/Fort_Nelson',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Dawson',
    ],
    country_code: 'CA',
  },
  {
    timezones: ['Europe/Zurich'],
    country_code: 'CH',
  },
  {
    timezones: ['America/Santiago', 'Pacific/Easter'],
    country_code: 'CL',
  },
  {
    timezones: ['Asia/Shanghai', 'Asia/Urumqi'],
    country_code: 'CN',
  },
  {
    timezones: ['Africa/Abidjan'],
    country_code: 'CI',
  },
  {
    timezones: ['Africa/Douala'],
    country_code: 'CM',
  },
  {
    timezones: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
    country_code: 'CD',
  },
  {
    timezones: ['Africa/Brazzaville'],
    country_code: 'CG',
  },
  {
    timezones: ['Pacific/Rarotonga'],
    country_code: 'CK',
  },
  {
    timezones: ['America/Bogota'],
    country_code: 'CO',
  },
  {
    timezones: ['Indian/Comoro'],
    country_code: 'KM',
  },
  {
    timezones: ['Atlantic/Cape_Verde'],
    country_code: 'CV',
  },
  {
    timezones: ['America/Costa_Rica'],
    country_code: 'CR',
  },
  {
    timezones: ['America/Havana'],
    country_code: 'CU',
  },
  {
    timezones: ['America/Curacao'],
    country_code: 'CW',
  },
  {
    timezones: ['Indian/Christmas'],
    country_code: 'CX',
  },
  {
    timezones: ['America/Cayman'],
    country_code: 'KY',
  },
  {
    timezones: ['Asia/Nicosia'],
    country_code: 'CY',
  },
  {
    timezones: ['Europe/Prague'],
    country_code: 'CZ',
  },
  {
    timezones: ['Europe/Berlin', 'Europe/Busingen'],
    country_code: 'DE',
  },
  {
    timezones: ['Africa/Djibouti'],
    country_code: 'DJ',
  },
  {
    timezones: ['America/Dominica'],
    country_code: 'DM',
  },
  {
    timezones: ['Europe/Copenhagen'],
    country_code: 'DK',
  },
  {
    timezones: ['America/Santo_Domingo'],
    country_code: 'DO',
  },
  {
    timezones: ['Africa/Algiers'],
    country_code: 'DZ',
  },
  {
    timezones: ['America/Guayaquil', 'Pacific/Galapagos'],
    country_code: 'EC',
  },
  {
    timezones: ['Africa/Cairo'],
    country_code: 'EG',
  },
  {
    timezones: ['Africa/Asmara'],
    country_code: 'ER',
  },
  {
    timezones: ['Africa/El_Aaiun'],
    country_code: 'EH',
  },
  {
    timezones: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
    country_code: 'ES',
  },
  {
    timezones: ['Europe/Tallinn'],
    country_code: 'EE',
  },
  {
    timezones: ['Africa/Addis_Ababa'],
    country_code: 'ET',
  },
  {
    timezones: ['Europe/Helsinki'],
    country_code: 'FI',
  },
  {
    timezones: ['Pacific/Fiji'],
    country_code: 'FJ',
  },
  {
    timezones: ['Atlantic/Stanley'],
    country_code: 'FK',
  },
  {
    timezones: ['Europe/Paris'],
    country_code: 'FR',
  },
  {
    timezones: ['Atlantic/Faroe'],
    country_code: 'FO',
  },
  {
    timezones: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
    country_code: 'FM',
  },
  {
    timezones: ['Africa/Libreville'],
    country_code: 'GA',
  },
  {
    timezones: ['Europe/London'],
    country_code: 'GB',
  },
  {
    timezones: ['Asia/Tbilisi'],
    country_code: 'GE',
  },
  {
    timezones: ['Europe/Guernsey'],
    country_code: 'GG',
  },
  {
    timezones: ['Africa/Accra'],
    country_code: 'GH',
  },
  {
    timezones: ['Europe/Gibraltar'],
    country_code: 'GI',
  },
  {
    timezones: ['Africa/Conakry'],
    country_code: 'GN',
  },
  {
    timezones: ['America/Guadeloupe'],
    country_code: 'GP',
  },
  {
    timezones: ['Africa/Banjul'],
    country_code: 'GM',
  },
  {
    timezones: ['Africa/Bissau'],
    country_code: 'GW',
  },
  {
    timezones: ['Africa/Malabo'],
    country_code: 'GQ',
  },
  {
    timezones: ['Europe/Athens'],
    country_code: 'GR',
  },
  {
    timezones: ['America/Grenada'],
    country_code: 'GD',
  },
  {
    timezones: [
      'America/Godthab',
      'America/Danmarkshavn',
      'America/Scoresbysund',
      'America/Thule',
    ],
    country_code: 'GL',
  },
  {
    timezones: ['America/Guatemala'],
    country_code: 'GT',
  },
  {
    timezones: ['America/Cayenne'],
    country_code: 'GF',
  },
  {
    timezones: ['Pacific/Guam'],
    country_code: 'GU',
  },
  {
    timezones: ['America/Guyana'],
    country_code: 'GY',
  },
  {
    timezones: ['Asia/Hong_Kong'],
    country_code: 'HK',
  },
  {
    timezones: ['America/Tegucigalpa'],
    country_code: 'HN',
  },
  {
    timezones: ['Europe/Zagreb'],
    country_code: 'HR',
  },
  {
    timezones: ['America/Port-au-Prince'],
    country_code: 'HT',
  },
  {
    timezones: ['Europe/Budapest'],
    country_code: 'HU',
  },
  {
    timezones: [
      'Asia/Jakarta',
      'Asia/Pontianak',
      'Asia/Makassar',
      'Asia/Jayapura',
    ],
    country_code: 'ID',
  },
  {
    timezones: ['Europe/Isle_of_Man'],
    country_code: 'IM',
  },
  {
    timezones: ['Indian/Chagos'],
    country_code: 'IO',
  },
  {
    timezones: ['Europe/Dublin'],
    country_code: 'IE',
  },
  {
    timezones: ['Asia/Baghdad'],
    country_code: 'IQ',
  },
  {
    timezones: ['Atlantic/Reykjavik'],
    country_code: 'IS',
  },
  {
    timezones: ['Asia/Jerusalem'],
    country_code: 'IL',
  },
  {
    timezones: ['Europe/Rome'],
    country_code: 'IT',
  },
  {
    timezones: ['America/Jamaica'],
    country_code: 'JM',
  },
  {
    timezones: ['Europe/Jersey'],
    country_code: 'JE',
  },
  {
    timezones: ['Asia/Amman'],
    country_code: 'JO',
  },
  {
    timezones: ['Asia/Tokyo'],
    country_code: 'JP',
  },
  {
    timezones: [
      'Asia/Almaty',
      'Asia/Qyzylorda',
      'Asia/Aqtobe',
      'Asia/Aqtau',
      'Asia/Oral',
    ],
    country_code: 'KZ',
  },
  {
    timezones: ['Africa/Nairobi'],
    country_code: 'KE',
  },
  {
    timezones: ['Asia/Bishkek'],
    country_code: 'KG',
  },
  {
    timezones: ['Asia/Phnom_Penh'],
    country_code: 'KH',
  },
  {
    timezones: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
    country_code: 'KI',
  },
  {
    timezones: ['America/St_Kitts'],
    country_code: 'KN',
  },
  {
    timezones: ['Asia/Seoul'],
    country_code: 'KR',
  },
  {
    timezones: ['Europe/Belgrade'],
    country_code: 'XK',
  },
  {
    timezones: ['Asia/Kuwait'],
    country_code: 'KW',
  },
  {
    timezones: ['Asia/Vientiane'],
    country_code: 'LA',
  },
  {
    timezones: ['Asia/Beirut'],
    country_code: 'LB',
  },
  {
    timezones: ['Africa/Monrovia'],
    country_code: 'LR',
  },
  {
    timezones: ['Africa/Tripoli'],
    country_code: 'LY',
  },
  {
    timezones: ['America/St_Lucia'],
    country_code: 'LC',
  },
  {
    timezones: ['Europe/Vaduz'],
    country_code: 'LI',
  },
  {
    timezones: ['Africa/Maseru'],
    country_code: 'LS',
  },
  {
    timezones: ['Europe/Vilnius'],
    country_code: 'LT',
  },
  {
    timezones: ['Europe/Luxembourg'],
    country_code: 'LU',
  },
  {
    timezones: ['Europe/Riga'],
    country_code: 'LV',
  },
  {
    timezones: ['Asia/Macau'],
    country_code: 'MO',
  },
  {
    timezones: ['America/Marigot'],
    country_code: 'MF',
  },
  {
    timezones: ['Africa/Casablanca'],
    country_code: 'MA',
  },
  {
    timezones: ['Europe/Monaco'],
    country_code: 'MC',
  },
  {
    timezones: ['Europe/Chisinau'],
    country_code: 'MD',
  },
  {
    timezones: ['Indian/Antananarivo'],
    country_code: 'MG',
  },
  {
    timezones: ['Indian/Maldives'],
    country_code: 'MV',
  },
  {
    timezones: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Chihuahua',
      'America/Ojinaga',
      'America/Hermosillo',
      'America/Tijuana',
      'America/Bahia_Banderas',
    ],
    country_code: 'MX',
  },
  {
    timezones: ['Pacific/Majuro', 'Pacific/Kwajalein'],
    country_code: 'MH',
  },
  {
    timezones: ['Europe/Skopje'],
    country_code: 'MK',
  },
  {
    timezones: ['Africa/Bamako'],
    country_code: 'ML',
  },
  {
    timezones: ['Europe/Malta'],
    country_code: 'MT',
  },
  {
    timezones: ['Europe/Podgorica'],
    country_code: 'ME',
  },
  {
    timezones: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
    country_code: 'MN',
  },
  {
    timezones: ['Pacific/Saipan'],
    country_code: 'MP',
  },
  {
    timezones: ['Africa/Maputo'],
    country_code: 'MZ',
  },
  {
    timezones: ['Africa/Nouakchott'],
    country_code: 'MR',
  },
  {
    timezones: ['America/Montserrat'],
    country_code: 'MS',
  },
  {
    timezones: ['America/Martinique'],
    country_code: 'MQ',
  },
  {
    timezones: ['Indian/Mauritius'],
    country_code: 'MU',
  },
  {
    timezones: ['Africa/Blantyre'],
    country_code: 'MW',
  },
  {
    timezones: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
    country_code: 'MY',
  },
  {
    timezones: ['Indian/Mayotte'],
    country_code: 'YT',
  },
  {
    timezones: ['Africa/Windhoek'],
    country_code: 'NA',
  },
  {
    timezones: ['Pacific/Noumea'],
    country_code: 'NC',
  },
  {
    timezones: ['Africa/Niamey'],
    country_code: 'NE',
  },
  {
    timezones: ['Pacific/Norfolk'],
    country_code: 'NF',
  },
  {
    timezones: ['Africa/Lagos'],
    country_code: 'NG',
  },
  {
    timezones: ['America/Managua'],
    country_code: 'NI',
  },
  {
    timezones: ['Pacific/Niue'],
    country_code: 'NU',
  },
  {
    timezones: ['Europe/Amsterdam'],
    country_code: 'NL',
  },
  {
    timezones: ['Europe/Oslo'],
    country_code: 'NO',
  },
  {
    timezones: ['Pacific/Nauru'],
    country_code: 'NR',
  },
  {
    timezones: ['Asia/Muscat'],
    country_code: 'OM',
  },
  {
    timezones: ['Asia/Karachi'],
    country_code: 'PK',
  },
  {
    timezones: ['America/Panama'],
    country_code: 'PA',
  },
  {
    timezones: ['Pacific/Pitcairn'],
    country_code: 'PN',
  },
  {
    timezones: ['America/Lima'],
    country_code: 'PE',
  },
  {
    timezones: ['Asia/Manila'],
    country_code: 'PH',
  },
  {
    timezones: ['Pacific/Palau'],
    country_code: 'PW',
  },
  {
    timezones: ['Pacific/Port_Moresby', 'Pacific/Bougainville'],
    country_code: 'PG',
  },
  {
    timezones: ['Europe/Warsaw'],
    country_code: 'PL',
  },
  {
    timezones: ['America/Puerto_Rico'],
    country_code: 'PR',
  },
  {
    timezones: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
    country_code: 'PT',
  },
  {
    timezones: ['America/Asuncion'],
    country_code: 'PY',
  },
  {
    timezones: ['Asia/Gaza', 'Asia/Hebron'],
    country_code: 'PS',
  },
  {
    timezones: ['Pacific/Tahiti', 'Pacific/Gambier'],
    country_code: 'PF',
  },
  {
    timezones: ['Asia/Qatar'],
    country_code: 'QA',
  },
  {
    timezones: ['Indian/Reunion'],
    country_code: 'RE',
  },
  {
    timezones: ['Europe/Bucharest'],
    country_code: 'RO',
  },
  {
    timezones: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Simferopol',
      'Europe/Volgograd',
      'Europe/Kirov',
      'Europe/Astrakhan',
      'Europe/Samara',
      'Europe/Ulyanovsk',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Barnaul',
      'Asia/Tomsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Chita',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Sakhalin',
      'Asia/Srednekolymsk',
      'Asia/Kamchatka',
      'Asia/Anadyr',
    ],
    country_code: 'RU',
  },
  {
    timezones: ['Africa/Kigali'],
    country_code: 'RW',
  },
  {
    timezones: ['Asia/Riyadh'],
    country_code: 'SA',
  },
  {
    timezones: ['Africa/Khartoum'],
    country_code: 'SD',
  },
  {
    timezones: ['Africa/Dakar'],
    country_code: 'SN',
  },
  {
    timezones: ['Asia/Singapore'],
    country_code: 'SG',
  },
  {
    timezones: ['Atlantic/South_Georgia'],
    country_code: 'GS',
  },
  {
    timezones: ['Arctic/Longyearbyen'],
    country_code: 'SJ',
  },
  {
    timezones: ['Pacific/Guadalcanal'],
    country_code: 'SB',
  },
  {
    timezones: ['Africa/Freetown'],
    country_code: 'SL',
  },
  {
    timezones: ['America/El_Salvador'],
    country_code: 'SV',
  },
  {
    timezones: ['Europe/San_Marino'],
    country_code: 'SM',
  },
  {
    timezones: ['Africa/Mogadishu'],
    country_code: 'SO',
  },
  {
    timezones: ['America/Miquelon'],
    country_code: 'PM',
  },
  {
    timezones: ['Europe/Belgrade'],
    country_code: 'RS',
  },
  {
    timezones: ['Africa/Juba'],
    country_code: 'SS',
  },
  {
    timezones: ['Africa/Sao_Tome'],
    country_code: 'ST',
  },
  {
    timezones: ['America/Paramaribo'],
    country_code: 'SR',
  },
  {
    timezones: ['Europe/Bratislava'],
    country_code: 'SK',
  },
  {
    timezones: ['Europe/Ljubljana'],
    country_code: 'SI',
  },
  {
    timezones: ['Europe/Stockholm'],
    country_code: 'SE',
  },
  {
    timezones: ['Africa/Mbabane'],
    country_code: 'SZ',
  },
  {
    timezones: ['America/Lower_Princes'],
    country_code: 'SX',
  },
  {
    timezones: ['Indian/Mahe'],
    country_code: 'SC',
  },
  {
    timezones: ['Asia/Damascus'],
    country_code: 'SY',
  },
  {
    timezones: ['America/Grand_Turk'],
    country_code: 'TC',
  },
  {
    timezones: ['Africa/Ndjamena'],
    country_code: 'TD',
  },
  {
    timezones: ['Africa/Lome'],
    country_code: 'TG',
  },
  {
    timezones: ['Asia/Bangkok'],
    country_code: 'TH',
  },
  {
    timezones: ['Asia/Dushanbe'],
    country_code: 'TJ',
  },
  {
    timezones: ['Pacific/Fakaofo'],
    country_code: 'TK',
  },
  {
    timezones: ['Asia/Ashgabat'],
    country_code: 'TM',
  },
  {
    timezones: ['Asia/Dili'],
    country_code: 'TL',
  },
  {
    timezones: ['Pacific/Tongatapu'],
    country_code: 'TO',
  },
  {
    timezones: ['America/Port_of_Spain'],
    country_code: 'TT',
  },
  {
    timezones: ['Africa/Tunis'],
    country_code: 'TN',
  },
  {
    timezones: ['Europe/Istanbul'],
    country_code: 'TR',
  },
  {
    timezones: ['Pacific/Funafuti'],
    country_code: 'TV',
  },
  {
    timezones: ['Asia/Taipei'],
    country_code: 'TW',
  },
  {
    timezones: ['Africa/Dar_es_Salaam'],
    country_code: 'TZ',
  },
  {
    timezones: ['Africa/Kampala'],
    country_code: 'UG',
  },
  {
    timezones: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
    country_code: 'UA',
  },
  {
    timezones: ['Pacific/Midway', 'Pacific/Wake'],
    country_code: 'UM',
  },
  {
    timezones: ['America/Montevideo'],
    country_code: 'UY',
  },
  {
    timezones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu',
    ],
    country_code: 'US',
  },
  {
    timezones: ['Asia/Samarkand', 'Asia/Tashkent'],
    country_code: 'UZ',
  },
  {
    timezones: ['Europe/Vatican'],
    country_code: 'VA',
  },
  {
    timezones: ['America/St_Vincent'],
    country_code: 'VC',
  },
  {
    timezones: ['America/Caracas'],
    country_code: 'VE',
  },
  {
    timezones: ['America/Tortola'],
    country_code: 'VG',
  },
  {
    timezones: ['America/St_Thomas'],
    country_code: 'VI',
  },
  {
    timezones: ['Asia/Ho_Chi_Minh'],
    country_code: 'VN',
  },
  {
    timezones: ['Pacific/Efate'],
    country_code: 'VU',
  },
  {
    timezones: ['Pacific/Wallis'],
    country_code: 'WF',
  },
  {
    timezones: ['Pacific/Apia'],
    country_code: 'WS',
  },
  {
    timezones: ['Asia/Aden'],
    country_code: 'YE',
  },
  {
    timezones: ['Africa/Johannesburg'],
    country_code: 'ZA',
  },
  {
    timezones: ['Africa/Lusaka'],
    country_code: 'ZM',
  },
  {
    timezones: ['Africa/Harare'],
    country_code: 'ZW',
  },
];
