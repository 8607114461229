export enum Route {
  dashboard = 'dashboard',
  profile = 'profile',
  security = 'security',
  information = 'information',
  payment = 'payment',
  payout = 'payout',
  payoutItem = 'payoutItem',
  historyFinance = 'historyFinance',
  financeCurrency = 'financeCurrency',
  balance = 'balance',
  goals = 'goals',
  offer = 'offer',
  offerPromoBanners = 'offerPromoBanners',
  offerGoals = 'offerGoals',
  offerDescription = 'offerDescription',
  report = 'report',
  postbacks = 'postbacks',
  createPostback = 'createPostback',
  historyPostback = 'historyPostback',
  editPostback = 'editPostback',
  conversions = 'conversions',
  conversion = 'conversion',
  fairspin = 'fairspin',
}
