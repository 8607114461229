import type { AppDependencies } from '@/shared/plugins/appContext';
import SessionService from '@/shared/services/SessionService';
import AxiosAdapter from '@/shared/services/http/AxiosAdapter';
import HttpService from '@/shared/services/http/HttpService';

export const createDependencies = (): AppDependencies => {
  const sessionService = new SessionService();
  return {
    sessionService,
    httpService: new HttpService(new AxiosAdapter(), sessionService),
  };
};
