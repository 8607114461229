<script setup lang="ts">
import { getTimezoneOffset, formatInTimeZone } from 'date-fns-tz';
import { computed, ref } from 'vue';
import ModalLayout from '@/entities/modal/ModalLayout.vue';
import KButton from '@/shared/kit/KButton.vue';
import KDropdownList from '@/shared/kit/list/KDropdownList.vue';
import KModal from '@/shared/kit/modal/KModal.vue';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { ct } from '@/shared/services/countryTimezones';

const DEF_COUNTRY = 'GB';

const props = defineProps<{
  initValue?: string;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void;
}>();
const { t: tCommon } = useTranslation('common');
const { t: tCountry } = useTranslation('countries');
const { t: tTimezone } = useTranslation('timezones');

const {
  actions: { update: userUpdate },
  isLoading: userLoading,
} = useUser();

const country = ref(
  props.initValue
    ? ct.getCountryForTimezone(props.initValue)?.country_code || DEF_COUNTRY
    : DEF_COUNTRY,
);

const countryPhrase = ref('');

const countriesList = computed(() =>
  ct.getAllCountries().map(({ country_code: code }) => ({
    value: code,
    title: tCountry(code),
  })),
);

const timezoneList = computed(() => {
  return ct
    .getCountry(country.value)!
    ?.timezones.map((tz) => {
      const utc = formatInTimeZone(new Date(), tz, 'xxx');
      return {
        value: tz,
        title: `(UTC ${utc}) ${tTimezone(tz)}`,
      };
    })
    .sort(({ value: a }, { value: b }) => {
      return getTimezoneOffset(a) - getTimezoneOffset(b);
    });
});

const timezone = ref(
  timezoneList.value.find(({ value }) => value === props.initValue)
    ? props.initValue
    : undefined,
);

const countryItem = computed({
  get() {
    return countriesList.value.find((item) => item.value === country.value);
  },
  set(item) {
    if (item?.value === country.value) {
      return;
    }
    countryPhrase.value = '';
    country.value = item?.value || DEF_COUNTRY;
    timezone.value = undefined;
  },
});

const onSubmit = () => {
  userUpdate({
    timezone: timezone.value,
  }).then(() => {
    emit('update:modelValue', false);
  });
};
const onSetUTC = () => {
  userUpdate({
    timezone: 'UTC',
  }).then(() => {
    emit('update:modelValue', false);
  });
};
</script>

<template>
  <KModal>
    <ModalLayout
      is="form"
      @submit.prevent="onSubmit"
      @close="emit('update:modelValue', false)"
    >
      <template #title>{{ tCommon('timezone.title') }}</template>
      <template #body>
        <div class="form-group">
          <KDropdownList
            item-title="title"
            item-value="value"
            :options="countriesList"
            return-object
            v-model="countryItem"
            v-model:filter-string="countryPhrase"
            filter="startsWith"
          />
        </div>
        <div class="form-group">
          <KDropdownList
            item-title="title"
            item-value="value"
            :placeholder="tCommon('timezone.placeholderTimezone')!"
            :options="timezoneList"
            v-model="timezone"
          />
        </div>
      </template>
      <template #actions>
        <KButton
          color="white"
          @click="onSetUTC"
          :disable="initValue === 'UTC'"
          >UTC-0</KButton
        >
        <KButton
          type="submit"
          color="primary"
          :loading="userLoading"
          :disable="initValue === timezone || !timezone"
          >{{ tCommon('action.confirm') }}</KButton
        >
      </template>
    </ModalLayout>
  </KModal>
</template>

<style scoped lang="scss"></style>
