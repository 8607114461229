import { isNil } from 'lodash';
import Cookies from 'universal-cookie';
import config from '@/shared/configs/config';
import type { SessionAccessData } from '@/shared/contracts/entity/AccessData';
import { getTimestampInSeconds } from '@/shared/utils/time';

const cookies = new Cookies();
const cookOptions = { path: '/' };
export default class SessionService {
  setAccessData(value: SessionAccessData) {
    cookies.set(config.nameCookie, value, cookOptions);
  }
  updateAccessData(value: Partial<SessionAccessData>) {
    console.debug('update auth - new rt: ', value.refresh_token);
    this.setAccessData({
      ...this.getAccessData(),
      ...value,
    } as SessionAccessData);
  }
  removeAccessData() {
    cookies.remove(config.nameCookie, cookOptions);
  }
  getAccessData() {
    return cookies.get<SessionAccessData | undefined | null>(config.nameCookie);
  }
  getAccessToken() {
    return this.getAccessData()?.access_token;
  }
  getRefreshToken() {
    return this.getAccessData()?.refresh_token;
  }
  hasAccess() {
    return !isNil(this.getAccessData());
  }
  isExpiredAccess() {
    const data = this.getAccessData();
    if (!data) {
      return false;
    }
    const { expires_at } = data;
    return getTimestampInSeconds() > parseInt(expires_at);
  }

  getImpersonating() {
    return this.getAccessData()?.impersonating_user;
  }
}
