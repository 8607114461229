<script lang="ts">
import HTML, { type HTMLAstNode } from 'html-parse-stringify';
import {
  type Component,
  computed,
  defineComponent,
  h,
  type PropType,
  type Slots,
  type VNodeChild,
} from 'vue';

const createRenderDomToVue = (options: {
  components: Record<string, Component>;
  slots: Slots;
}) => {
  const renderDomToVue = (ast: HTMLAstNode | HTMLAstNode[]): VNodeChild => {
    if (Array.isArray(ast)) {
      return ast.map((item) => renderDomToVue(item));
    }
    const { components, slots } = options;
    const node = ast;
    if (node.type === 'text') {
      return node.content;
    }
    if (node.name === 'slot') {
      const slotName = node.attrs.name || 'default';
      if (slotName in slots) {
        return slots[slotName]?.(node.attrs);
      } else {
        return node.children.map((child) => renderDomToVue(child));
      }
    }

    const Component = components?.[node.name] || node.name;

    return h(
      Component,
      node.attrs,
      node.children.map((child) => renderDomToVue(child)),
    );
  };
  return renderDomToVue;
};

export default defineComponent({
  props: {
    source: {
      type: String,
      required: true,
    },
    components: {
      type: Object as PropType<Record<string, Component>>,
      default: () => ({}),
    },
  },
  setup(props, { slots }) {
    const render = computed(() =>
      createRenderDomToVue({
        components: props.components,
        slots,
      }),
    );

    const ast = computed(() => {
      const source = props.source.replace(
        /({{(.*?)}})/gm,
        (m, p1, p2) => `<slot name='${p2}' />`,
      );
      return HTML.parse(source);
    });

    return () => {
      return ast.value.map((node) => render.value(node));
    };
  },
});
</script>
