<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { VueFinalModal } from 'vue-final-modal';

const props = defineProps<{
  clickToClose?: boolean;
  modelValue?: boolean;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void;
}>();

const model = useVModel(props, 'modelValue', emit);
</script>

<template>
  <VueFinalModal
    :content-class="$style.content"
    overlay-transition="vfm-fade"
    content-transition="vfm-slide-up"
    v-model="model"
  >
    <div :class="$style.overflowWrapper">
      <div
        :class="$style.wrapper"
        @click.self="() => clickToClose && emit('update:modelValue', false)"
      >
        <slot />
      </div>
    </div>
  </VueFinalModal>
</template>
<style lang="scss" module>
.content {
  position: absolute;
  inset: 0;
}
.overflowWrapper {
  overflow: auto;
  height: 100%;
  position: absolute;
  inset: 0;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  @media (min-width: 768px) {
    margin: 30px auto;
  }
}
</style>
