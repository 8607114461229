<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { useHead } from '@vueuse/head';
import { useTranslation } from 'i18next-vue';
import AuthPreloading from '@/widgets/auth-preloading/AuthPreloading.vue';
import EndpointsPreloading from '@/widgets/endpoints-preloading/EndpointsPreloading.vue';

const { i18next } = useTranslation();

useHead({
  htmlAttrs: () => ({
    lang: i18next.language,
  }),
});
</script>

<template>
  <EndpointsPreloading>
    <AuthPreloading>
      <RouterView />
    </AuthPreloading>
  </EndpointsPreloading>
  <VueQueryDevtools />
</template>
