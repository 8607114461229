<script setup lang="ts">
import { useTranslation } from '@/shared/compositions/useTranslation';
import { Route } from '@/shared/contracts/route';

defineProps<{
  showCode?: boolean;
}>();
const { t } = useTranslation('common', { keyPrefix: 'errorPage.notFound' });
</script>

<template>
  <div class="notfound text-center">
    <h1 v-if="showCode">404</h1>
    <h3 class="font-bold">{{ t('title') }}</h3>
    <div class="error-desc">
      {{ t('text') }}
      <br />
      <RouterLink
        class="btn btn-primary m-t"
        :to="{ name: Route.dashboard }"
        >Dashboard</RouterLink
      >
    </div>
  </div>
</template>

<style lang="scss"></style>
