<script setup lang="ts">
import { computed } from 'vue';
import VInterpolation from '@/shared/components/VInterpolation.vue';
import { formatCurrency } from '@/entities/currency/formatCurrency';

const props = defineProps<{
  amount: string | number;
  iso: string;
  type?: 'default' | 'short' | 'raw';
}>();

const amountSource = computed(() => {
  const value = formatCurrency(
    typeof props.amount === 'string' ? parseFloat(props.amount) : props.amount,
    props.iso,
    props.type,
  );

  return props.iso === 'BTC' ? value.replace('BTC', '{{btc}}') : value;
});
</script>

<template>
  <VInterpolation
    v-if="iso === 'BTC'"
    :source="amountSource"
  >
    <template #btc>
      <span class="bitcoin">B</span>
    </template>
  </VInterpolation>
  <template v-else>{{ amountSource }}</template>
</template>
