import type { VNodeArrayChildren } from 'vue';
import { Fragment, Text, Comment, isVNode } from 'vue';

export function vNodeIsEmpty(vnodes: VNodeArrayChildren): boolean {
  return vnodes.every((node) => {
    if (Array.isArray(node)) return vNodeIsEmpty(node);
    if (!isVNode(node)) return false;
    if (node.type === Comment) return true;
    if (node.type === Text && !(node.children as string).trim()) return true;
    if (
      node.type === Fragment &&
      vNodeIsEmpty(node.children as VNodeArrayChildren)
    ) {
      return true;
    }

    return false;
  });
}
