<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useTranslation } from '@/shared/compositions/useTranslation';

const props = defineProps<{
  nameMap?: Record<string, string>;
}>();
const router = useRouter();
const route = useRoute();
const { t } = useTranslation('common');

const translateName = (name: string) => {
  if (typeof props.nameMap?.[name] === 'string') {
    return props.nameMap[name];
  }
  return t(
    [`breadcrumbs.${name}`, `menu.${name}`, `page.${name}.header`],
    route.params,
  );
};
</script>

<template>
  <ol
    class="breadcrumb"
    v-if="route.meta.breadcrumbs"
  >
    <li
      v-for="item in route.meta.breadcrumbs"
      :key="item.name"
    >
      <strong v-if="item.name === route.name || !router.hasRoute(item.name)">{{
        translateName(item.name)
      }}</strong>
      <RouterLink
        v-else
        :to="item"
        >{{ translateName(item.name) }}</RouterLink
      >
    </li>
  </ol>
</template>
