import { type InjectionKey, type Plugin, inject } from 'vue';
import type SessionService from '@/shared/services/SessionService';
import type HttpService from '@/shared/services/http/HttpService';

export type AppDependencies = {
  sessionService: SessionService;
  httpService: HttpService;
};

const appDependenciesInjectionKey: InjectionKey<AppDependencies> =
  Symbol('app-context');

export const appContext: Plugin<{
  dependencies: AppDependencies;
}> = {
  install(app, options) {
    app.provide(appDependenciesInjectionKey, options.dependencies);
  },
};

export const useAppDependencies = () => {
  const dependencies = inject(appDependenciesInjectionKey);
  if (!dependencies) {
    throw new Error('Application context is not initialized');
  }
  return dependencies;
};
