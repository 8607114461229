import { reactive } from 'vue';
import { injectContext } from '@/shared/compositions/endpoints/context';

export const useEndpoints = () => {
  const endpointsQuery = injectContext();
  const { data, isLoading } = endpointsQuery;

  return {
    isLoading,
    entity: reactive(data),
  };
};
