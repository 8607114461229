<script setup lang="ts">
import { computed } from 'vue';
import WManager from '@/widgets/sidebar/ui/WManager.vue';
import WNavigation from '@/widgets/sidebar/ui/WNavigation.vue';
import bfLogo from '@/shared/assets/img/bf_logo-white.svg?url';
import junketLogo from '@/shared/assets/img/logo-fs-junket.png?url';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';
import getEnv from '@/shared/getEnv';
import { useSidebarStore } from '@/shared/stores/sidebarStore';

const { cpaUrl } = getEnv();
const { data: userData } = useUser();
const { i18next } = useTranslation();
const lang = computed(() => i18next.language);
const store = useSidebarStore();
</script>

<template>
  <div :class="['sidebar', { opened: store.isOpen }]">
    <div
      class="sidebar__bgr"
      @click="store.close"
    />
    <div
      class="sidebar__inner"
      id="sidebar-inner"
    >
      <RouterLink
        v-if="userData.isJunket"
        :to="{ name: Route.dashboard }"
        class="sidebar__logo"
      >
        <img
          :src="junketLogo"
          alt="logo"
        />
      </RouterLink>
      <a
        v-else
        :href="`${cpaUrl}/${lang}`"
        class="sidebar__logo"
      >
        <img
          :src="bfLogo"
          alt="logo"
        />
      </a>
      <WNavigation />
      <WManager />
    </div>
  </div>
</template>
