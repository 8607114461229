<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import KDropdown from '@/shared/kit/dropdown/KDropdown.vue';
import FieldBox from '@/shared/kit/field/FieldBox.vue';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    disabled?: boolean;
    focused?: boolean;
    tabindex?: number;
  }>(),
  {
    disabled: false,
    tabindex: 0,
  },
);
const emit = defineEmits<{
  'update:modelValue': [isOpen: boolean];
}>();
const model = useVModel(props, 'modelValue', emit);
</script>

<template>
  <KDropdown
    v-model="model"
    :disabled="disabled"
  >
    <template #reference="{ setRef, getReferenceProps }">
      <FieldBox
        :ref="setRef"
        :tabindex="tabindex"
        :disabled="disabled"
        :focused="focused"
        v-bind="getReferenceProps()"
      >
        <slot name="target" />
      </FieldBox>
    </template>
    <template #widget="widget">
      <slot
        name="widget"
        v-bind="widget"
      />
    </template>
  </KDropdown>
</template>
