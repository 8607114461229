import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getLocaleJsonCodeList, getLocaleJsonLoader } from '@/locales-loader';
import getEnv from '@/shared/getEnv';

const env = getEnv();

const i18n = i18next.createInstance({
  load: 'languageOnly',
  lng: env.defaultLang,
  fallbackLng: 'en',
  supportedLngs: getLocaleJsonCodeList(),
  ns: ['common', 'timezones', 'date'],
});
i18n.use(
  resourcesToBackend((lng: string, ns: string) => {
    const loader = getLocaleJsonLoader(lng, `${ns}.json`, {
      fallback: null,
    });
    return loader().then((json) => JSON.parse(json));
  }),
);

export { i18n };
