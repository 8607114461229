const getEnv = () => {
  const cpaUrl = import.meta.env.VUE_APP_CPA_URL as string;
  const tdsUrl = import.meta.env.VUE_APP_TDS_URL as string;
  const clientId = import.meta.env.VUE_APP_CLIENT_ID as string;

  if (!cpaUrl || !clientId) {
    throw Error('critical environment data is uninitialized');
  }

  return {
    isDev: import.meta.env.DEV,
    mode: import.meta.env.MODE,
    baseUrl: import.meta.env.BASE_URL,
    defaultLang: import.meta.env.VUE_APP_DEFAULT_LANG,
    captchaKey: import.meta.env.VUE_APP_CAPTCHA_KEY,
    cpaUrl,
    tdsUrl,
    clientId,
  };
};

export default getEnv;
