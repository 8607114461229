import { refreshToken } from '@/shared/api/ident';
import type {
  HttpClient,
  HttpRequest,
  HttpResponse,
  TypedHttpRequest,
} from '@/shared/contracts/http';
import { HttpError, HttpErrorReason } from '@/shared/contracts/http';
import type {
  ApiResponseStructure,
  ErrorStructure,
} from '@/shared/contracts/response';
import getEnv from '@/shared/getEnv';
import { redirectToLogin } from '@/shared/lib';
import type SessionService from '@/shared/services/SessionService';

const { clientId, cpaUrl } = getEnv();

const formData = (data: Record<string, any>) => {
  const formData = new FormData();

  for (const key in data) {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  }

  return formData;
};
function normalizeResponse<T = unknown, D = unknown>(
  response: HttpResponse<ApiResponseStructure<T>, D>,
): HttpResponse<
  | T
  | {
      error: ErrorStructure;
      data?: T;
    },
  D
> {
  const {
    data: { code, message, error, ...other },
  } = response;
  const data: T = 'response' in other ? other.response : (other as T);
  return {
    ...response,
    statusText: message || response.statusText,
    status: error?.code || code || response.status,
    data: error
      ? {
          error,
          data,
        }
      : data,
  };
}

type RequestExtra = {
  secure: /*'public' |*/ 'private';
  handleStatus: boolean | ((status: number) => boolean);
};

export default class HttpService {
  private refreshRequest: null | Promise<void> = null;
  apiUrl?: string;

  constructor(
    private client: HttpClient,
    private session: SessionService,
  ) {}

  private getApiUrl(path: string) {
    if (import.meta.env.DEV) {
      return `/dev-api/${path.replace(/^\//, '')}`;
    }
    if (this.apiUrl) {
      if (typeof path === 'string' && path.startsWith('/api/')) {
        path = path.replace('/api/', '/');
        return this.apiUrl + path;
      }
    }
    return new URL(path, cpaUrl).toString();
  }

  public request<T = unknown, D = unknown>(
    request: HttpRequest<D> | TypedHttpRequest<T, D>,
    params: RequestExtra = {
      secure: 'private',
      handleStatus: false,
    },
  ): Promise<HttpResponse<T, D>> {
    return this.innerRequest(request, {
      repeated: false,
      ...params,
    });
  }

  private apiRequest<T = unknown, D = unknown>(
    request: HttpRequest<D> | TypedHttpRequest<T, D>,
  ): Promise<HttpResponse<T, D>> {
    return this.client
      .request({
        ...request,
        url: this.getApiUrl(request.url),
      })
      .then((response: HttpResponse<ApiResponseStructure<T>, D>) => {
        const normalizedResponse = normalizeResponse(response);

        if (
          normalizedResponse.status < 200 ||
          normalizedResponse.status >= 300
        ) {
          throw new HttpError(
            normalizedResponse.statusText &&
            normalizedResponse.statusText !== response.statusText
              ? normalizedResponse.statusText
              : `Request failed with status code ${normalizedResponse.status}`,
            HttpErrorReason.BAD_STATUS,
            request,
            response,
          );
        }
        if (
          typeof normalizedResponse.data === 'object' &&
          normalizedResponse.data &&
          'error' in normalizedResponse.data
        ) {
          throw new HttpError(
            normalizedResponse.data.error.message ||
            (normalizedResponse.statusText &&
              normalizedResponse.statusText !== 'OK')
              ? normalizedResponse.statusText!
              : 'Request returned an error',
            null,
            request,
            response,
          );
        }
        return normalizedResponse as HttpResponse<T, D>;
      });
  }

  private async innerRequest<T = unknown, D = unknown>(
    request: HttpRequest<D> | TypedHttpRequest<T, D>,
    params: RequestExtra & {
      repeated: boolean;
    },
  ): Promise<HttpResponse<T, D>> {
    if (params.secure === 'private' && this.refreshRequest) {
      console.debug('returned last refresh promise');
      await this.refreshRequest;
    }

    if (
      params.secure === 'private' &&
      this.session.isExpiredAccess() &&
      !params.repeated
    ) {
      console.debug('refresh bcs expired');
      console.debug('request: ', JSON.stringify(request));
      await this.refreshAccess();
    }

    const common = {
      access_token:
        params.secure === 'private' ? this.session.getAccessToken() : undefined,
    };

    const data =
      request.method.toLowerCase() !== 'get'
        ? {
            ...common,
            ...request.data,
          }
        : request.data;

    const requestParams =
      request.method.toLowerCase() === 'get'
        ? {
            ...common,
            ...request.params,
          }
        : request.params;
    return this.apiRequest({
      ...request,
      params: requestParams,
      data: data && (formData(data) as D),
    }).catch(async (reason) => {
      if (!(reason instanceof HttpError)) {
        throw reason;
      }
      if (reason.code === HttpErrorReason.CANCELED) {
        throw reason;
      }
      const { response, request } = reason;
      if (!response) {
        throw reason;
      }
      const normalizedResponse = normalizeResponse(response);
      const { status } = normalizedResponse;
      if (status === 401) {
        if (params.repeated) {
          console.debug('redirect to login bcs repeat bad rq');
          redirectToLogin();
          throw reason;
        }
        console.debug('refresh bcs bad rq');
        console.debug('response: ', JSON.stringify(response));
        await this.refreshAccess();

        return this.innerRequest(request, {
          ...params,
          repeated: true,
        });
      }
      throw reason;
    });
  }

  public refreshAccess() {
    if (this.refreshRequest) {
      console.debug('returned last refresh promise');
      return this.refreshRequest;
    }
    const access_token = this.session.getAccessToken();
    const refresh_token = this.session.getRefreshToken();
    if (!refresh_token || !access_token) {
      throw new Error('Token not found');
    }
    const request = refreshToken({
      refresh_token,
      client_id: clientId,
    });
    console.debug('send refresh - rt: ', refresh_token);
    this.refreshRequest = this.apiRequest({
      ...request,
    })
      .then((response) => {
        const { data } = response;
        this.session.updateAccessData(data);
      })
      .catch((reason) => {
        console.warn('redirect to login bcs bad refresh');
        console.debug('reason: ', JSON.stringify(reason));
        redirectToLogin();
      })
      .finally(() => {
        this.refreshRequest = null;
      });
    return this.refreshRequest;
  }
}
