import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTruePlayStore = defineStore('truePlay', () => {
  const isLoading = ref(false);
  const isIntegrated = ref<boolean | null>(null);

  const setLoading = (data: boolean) => {
    isLoading.value = data;
  };
  const setIntegrated = (data: boolean) => {
    isIntegrated.value = data;
  };
  return {
    isLoading,
    isIntegrated,
    setLoading,
    setIntegrated,
  };
});
