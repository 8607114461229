import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';

export const useSidebarStore = defineStore('sidebar', () => {
  const isOpen = ref(false);
  function toggle() {
    isOpen.value = !isOpen.value;
  }
  function open() {
    isOpen.value = true;
  }
  function close() {
    isOpen.value = false;
  }
  return { isOpen, toggle, open, close };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSidebarStore, import.meta.hot));
}
