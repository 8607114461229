import type { MaybeRef } from '@vueuse/shared';
import { unref } from 'vue';
import type {
  ElementProps,
  FloatingContext,
} from '@/shared/compositions/floating/types';

export const useClick = (
  context: FloatingContext,
  props: {
    toggle?: MaybeRef<boolean>;
    disabled?: MaybeRef<boolean>;
  } = {},
): ElementProps => {
  const { open } = context;
  const { toggle = true, disabled = false } = props;

  return {
    reference: {
      onClick: () => {
        if (unref(disabled)) {
          return;
        }
        if (open.value && unref(toggle)) {
          open.value = false;
        } else {
          open.value = true;
        }
      },
    },
  };
};
