import { get, type PropertyPath } from 'lodash';

export type SelectItemKey =
  | null
  | undefined
  | PropertyPath
  | ((item: any, fallback?: any) => any);
export function getPropertyFromItem(
  item: any,
  property: SelectItemKey,
  fallback?: any,
): any {
  if (property == null) return item === undefined ? fallback : item;

  if (typeof property === 'function') {
    const value = property(item, fallback);

    return typeof value === 'undefined' ? fallback : value;
  }

  if (item !== Object(item)) {
    return fallback;
  }

  return get(item, property, fallback);
}
