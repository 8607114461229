<script setup lang="ts">
import { useTranslation } from '@/shared/compositions/useTranslation';

const { t } = useTranslation('common', { keyPrefix: 'errorPage.server' });
</script>

<template>
  <div class="notfound text-center">
    <h1>500</h1>
    <h3 class="font-bold">{{ t('title') }}</h3>
    <div class="error-desc">
      {{ t('text') }}
    </div>
  </div>
</template>

<style lang="scss"></style>
