export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'head'
  | 'HEAD'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'purge'
  | 'PURGE'
  | 'link'
  | 'LINK'
  | 'unlink'
  | 'UNLINK';

export type HeaderValue = string | string[] | number | boolean | null;
export type ResponseType =
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream';
export type HttpRequest<D = any> = {
  url: string;
  method: Method;
  headers?: Record<string, HeaderValue>;
  params?: any;
  paramsSerializer?: (params: Record<string, any>) => string;
  data?: D;
  responseType?: ResponseType;
  signal?: AbortSignal;
};

const $retType: unique symbol = Symbol();

export type TypedHttpRequest<Res, D = any> = HttpRequest<D> & {
  readonly [$retType]: Res;
};
export type HttpResponse<T = any, D = any> = {
  data: T;
  status: number;
  statusText?: string;
  headers: Partial<
    Record<string, string> & {
      'set-cookie'?: string[];
    }
  >;
  request: HttpRequest<D>;
};

export interface HttpClient {
  request<D>(request: HttpRequest<D>): Promise<HttpResponse>;
}

export enum HttpErrorReason {
  CONN_ABORTED = 'CONN_ABORTED',
  TIME_OUT = 'TIME_OUT',
  ERR_NETWORK = 'ERR_NETWORK',
  BAD_STATUS = 'BAD_STATUS',
  CANCELED = 'CANCELED',
}

export class HttpError extends Error {
  public readonly name: string;
  constructor(
    message: string,
    public code: HttpErrorReason | null,
    public request: HttpRequest,
    public response?: HttpResponse,
  ) {
    super(message);
    this.name = 'AppHttpError';
  }
}
