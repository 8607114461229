<script setup lang="ts">
import { useHead } from '@vueuse/head';
import { computed } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import MainContentLayout from '@/entities/main-content-layout/MainContentLayout.vue';
import NotFoundView from '@/view/NotFoundView.vue';
import WBreadcrumbs from '@/widgets/breadcrumbs/WBreadcrumbs.vue';
import WFooter from '@/widgets/footer/WFooter.vue';
import WHeader from '@/widgets/header/WHeader.vue';
import WNotification from '@/widgets/notification/WNotification.vue';
import WSidebar from '@/widgets/sidebar/WSidebar.vue';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import { Route } from '@/shared/contracts/route';
import getEnv from '@/shared/getEnv';
import { canNavigate } from '@/shared/guard/navigate';

const { isDev } = getEnv();
const { t } = useTranslation('common');
const route = useRoute();
const user = useUser();

useHead({
  title: computed(
    () =>
      `${isDev ? 'Dev: ' : ''}${user.data.isJunket ? 'Junket' : 'Bona Fides'} Publisher Panel`,
  ),
  link: computed(() => [
    {
      rel: 'shortcut icon',
      href: user.data.isJunket
        ? '/favicons/junket.png'
        : '/favicons/favicon.svg',
    },
  ]),
});

const pageName = computed(() => route.name as Route);
const hasAccess = computed(() => {
  const roles = user.data.roles || {};
  return Array.isArray(roles) && roles.includes('ROLE_PUBLISHER');
});

const canNavigateRef = computed(() =>
  canNavigate(pageName.value, user.entity.value!),
);
</script>

<template>
  <NotFoundView v-if="!canNavigateRef" />
  <div
    v-else
    id="wrapper"
    :class="`company-${user.data.isJunket ? 'junket' : 'bf'}`"
  >
    <div
      v-if="!hasAccess"
      class="auth-controller-container"
    >
      <p>{{ t('accessDeniedAlert') }}</p>
    </div>
    <WSidebar />
    <div
      class="page-wrapper"
      id="page-wrapper"
      style="
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto 1fr;
      "
    >
      <WHeader />
      <WNotification v-if="!user.data.isJunket" />
      <RouterView v-if="route.meta.customLayout" />
      <MainContentLayout v-else>
        <template #breadcrumbs><WBreadcrumbs /></template>
        <template #default><RouterView /></template>
      </MainContentLayout>
      <WFooter />
    </div>
    <ModalsContainer />
  </div>
</template>
