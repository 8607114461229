<script setup lang="ts">
import { cloneDeep } from 'lodash';
import type { Simplify, WritableDeep } from 'type-fest';
import { computed } from 'vue';
import { useTranslation } from '@/shared/compositions/useTranslation';
import { useUser } from '@/shared/compositions/user/useUser';
import config from '@/shared/configs/config';
import type { Manager } from '@/shared/contracts/dto/UserResponseDto';

const { t } = useTranslation('common');

const { entity } = useUser();
const manager = computed<Manager>(() => {
  return (
    entity.value?.responsible_manager ||
    (cloneDeep(config.static.managerInfo) as Simplify<
      WritableDeep<typeof config.static.managerInfo>
    >)
  );
});
const contacts = computed(() => [
  ...(manager.value.contacts || config.static.managerInfo.contacts),
  {
    type: 'email',
    value: manager.value.email || config.static.managerInfo.email,
  },
]);
const isPersonalManager = computed(
  () => !!(manager.value.name || manager.value.surname),
);
const title = computed(() =>
  t(`manager.${isPersonalManager.value ? 'help' : 'support'}`),
);

const availableContactType = [
  'email',
  'skype',
  'telegram',
  'whatsapp',
] as const;
const getContactLink = (contact: { type: string; value: string }): string => {
  switch (contact.type) {
    case 'email':
      return `mailto:${contact.value}`;
    case 'skype':
      return `skype:${contact.value}?call`;
    case 'telegram':
      return `https://teleg.run/${contact.value}?call`;
    case 'whatsapp':
      return `https://wa.me/${contact.value}?call`;
  }
  return '';
};
</script>

<template>
  <div class="sidebar__manager">
    <div class="heading">{{ title }}</div>

    <div
      v-if="isPersonalManager"
      class="sidebar__manager-title"
    >
      {{ t('manager.manager') }}
    </div>

    <div
      v-if="isPersonalManager"
      class="sidebar__manager-name"
    >
      {{ manager.name }}
    </div>

    <div class="manager-contacts">
      <template
        v-for="contact in contacts"
        :key="`${contact.type}:${contact.value}`"
      >
        <div
          class="contacts-block"
          v-if="availableContactType.includes(contact.type)"
        >
          <span class="contacts-type">{{ t(`contact.${contact.type}`) }}</span>
          <a
            :href="getContactLink(contact)"
            target="_blank"
            >{{ contact.value }}</a
          >
        </div>
      </template>
    </div>
  </div>
</template>
