<script setup lang="ts">
import config from '@/shared/configs/config';
import { useNotification } from '@/widgets/notification/lib/useNotification';

const {
  polling: { data, isLoading },
  close,
  waitingNewData,
} = useNotification();

const item = data;
const onClose = () => {
  close({ id: item.value!.id });
};
</script>

<template>
  <div class="notification">
    <div
      v-if="item"
      class="alert"
      :class="{
        [`alert-${item.appearance}`]: item.appearance !== 'default',
      }"
    >
      <div class="alert__inner">
        <div class="alert__inner-social">
          <a
            :href="config.social.facebook"
            target="_blank"
          >
            <p class="facebook" />
          </a>
          <a
            :href="config.social.telegram"
            target="_blank"
          >
            <p class="telegram" />
          </a>
          <a
            :href="config.social.instagram"
            target="_blank"
          >
            <p class="instagram" />
          </a>
          <a
            :href="config.social.twitter"
            target="_blank"
          >
            <p class="twitter" />
          </a>
          <a
            :href="config.social.linkedin"
            target="_blank"
          >
            <p class="linkedin" />
          </a>
        </div>
        <div
          class="alert__inner-message"
          v-html="item.content"
        />
      </div>
      <button
        type="button"
        class="close"
        @click="onClose"
        :disabled="isLoading || waitingNewData"
      >
        <span aria-hidden="true">×</span>
        <span class="sr-only">Close</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
