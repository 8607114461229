import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import { parseInt, padStart } from 'lodash';
import type { DateValue, SimpleDateParts } from '@/shared/contracts/time';
import { isNumeric } from '@/shared/utils/lang';

const pad = (value: string | number, length: number) =>
  padStart(value.toString(), length, '0');

export const unix = (date: Date) => Math.floor(date.getTime() / 1000);

export function getTimestampInSeconds() {
  return unix(new Date());
}
export const parseDate = (
  value: DateValue,
  params?: {
    fromMilliseconds?: boolean;
    timezone?: string;
  },
) => {
  if (isNumeric(value)) {
    const source = parseInt(value as string);
    return new Date(params?.fromMilliseconds ? source : source * 1000);
  }
  if (value instanceof Date && params?.timezone) {
    return zonedTimeToUtc(value, params.timezone);
  }
  return toDate(value, {
    timeZone: params?.timezone,
  });
};
export function getDateFromParts(
  parts: Partial<SimpleDateParts>,
  timezone = '',
) {
  const d = new Date();
  const {
    year = d.getFullYear(),
    month = d.getMonth() + 1,
    day = d.getDate(),
    hours: hrs = 0,
    minutes: min = 0,
    seconds: sec = 0,
    milliseconds: ms = 0,
  } = parts;

  if (timezone) {
    const dateString = `${pad(year, 4)}-${pad(month, 2)}-${pad(day, 2)}T${pad(
      hrs,
      2,
    )}:${pad(min, 2)}:${pad(sec, 2)}.${pad(ms, 3)}`;
    return toDate(dateString, { timeZone: timezone });
  }
  return new Date(year, month - 1, day, hrs, min, sec, ms);
}

export function getDateParts(date: Date, timezone?: string): SimpleDateParts {
  let tzDate = new Date(date.getTime());
  if (timezone) {
    tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    tzDate.setMilliseconds(date.getMilliseconds());
  }

  const year = tzDate.getFullYear();
  const month = tzDate.getMonth() + 1;
  const day = tzDate.getDate();
  const hours = tzDate.getHours();
  const minutes = tzDate.getMinutes();
  const seconds = tzDate.getSeconds();
  const milliseconds = tzDate.getMilliseconds();

  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}
